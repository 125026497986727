angular.module('help.interface', [])
.constant('INTERFACE', {
    article: '/ap-ns/help/article',
    tree: '/ap-ns/help/tree'
}).constant('PHONEliST',[
    {
        "english_name":"Andorra",
        "chinese_name":"安道尔",
        "country_code":"AD",
        "areacode":"376",
        "className":"ad"
    },
    {
        "english_name":"United Arab Emirates",
        "chinese_name":"阿拉伯联合酋长国",
        "country_code":"AE",
        "areacode":"971",
        "className":"ae" 
    },
    {
        "english_name":"Afghanistan",
        "chinese_name":"阿富汗",
        "country_code":"AF",
        "areacode":"93",
        "className":"af"         
    },
    {
        "english_name":"Antigua and Barbuda",
        "chinese_name":"安提瓜和巴布达",
        "country_code":"AG",
        "areacode":"1268",
        "className":"ag"
    },
    {
        "english_name":"Anguilla",
        "chinese_name":"安圭拉",
        "country_code":"AI",
        "areacode":"1264",
        "className":"ai"
    },
    {
        "english_name":"Albania",
        "chinese_name":"阿尔巴尼亚",
        "country_code":"AL",
        "areacode":"355",
        "className":"al"
    },
    {
        "english_name":"Armenia",
        "chinese_name":"亚美尼亚",
        "country_code":"AM",
        "areacode":"374",
        "className":"am"
    },
    {
        "english_name":"Angola",
        "chinese_name":"安哥拉",
        "country_code":"AO",
        "areacode":"244",
        "className":"ao"
    },
    {
        "english_name":"Argentina",
        "chinese_name":"阿根廷",
        "country_code":"AR",
        "areacode":"54",
        "className":"ar"            
    },
    {
        "english_name":"American Samoa",
        "chinese_name":"美属萨摩亚",
        "country_code":"AS",
        "areacode":"1684",
        "className":"as"
    },
    {
        "english_name":"Austria",
        "chinese_name":"奥地利",
        "country_code":"AT",
        "areacode":"43",
        "className":"at"           
    },
    {
        "english_name":"Australia",
        "chinese_name":"澳大利亚",
        "country_code":"AU",
        "areacode":"61",    
        "className":"au"            
    },
    {
        "english_name":"Aruba",
        "chinese_name":"阿鲁巴",
        "country_code":"AW",
        "areacode":"297",
        "className":"aw"
    },
    {
        "english_name":"Azerbaijan",
        "chinese_name":"阿塞拜疆",
        "country_code":"AZ",
        "areacode":"994",
        "className":"az"
    },
    {
        "english_name":"Bosniaand Herzegovina",
        "chinese_name":"波斯尼亚和黑塞哥维那",
        "country_code":"BA",
        "areacode":"387",
        "className":"ba"
    },
    {
        "english_name":"Barbados",
        "chinese_name":"巴巴多斯",
        "country_code":"BB",
        "areacode":"1246",
        "className":"bb"
    },
    {
        "english_name":"Bangladesh",
        "chinese_name":"孟加拉国",
        "country_code":"BD",
        "areacode":"880",
        "className":"bd"
    },
    {
        "english_name":"Belgium",
        "chinese_name":"比利时",
        "country_code":"BE",
        "areacode":"32",
        "className":"be"           
    },
    {
        "english_name":"Burkina Faso",
        "chinese_name":"布基纳法索",
        "country_code":"BF",
        "areacode":"226",
        "className":"bf"
    },
    {
        "english_name":"Bulgaria",
        "chinese_name":"保加利亚",
        "country_code":"BG",
        "areacode":"359",
        "className":"bg"
    },
    {
        "english_name":"Bahrain",
        "chinese_name":"巴林",
        "country_code":"BH",
        "areacode":"973",
        "className":"bh"
    },
    {
        "english_name":"Burundi",
        "chinese_name":"布隆迪",
        "country_code":"BI",
        "areacode":"257",
        "className":"bi"
    },
    {
        "english_name":"Benin",
        "chinese_name":"贝宁",
        "country_code":"BJ",
        "areacode":"229",
        "className":"bj"
    },
    {
        "english_name":"Bermuda",
        "chinese_name":"百慕大群岛",
        "country_code":"BM",
        "areacode":"1441",
        "className":"bm"
    },
    {
        "english_name":"Brunei",
        "chinese_name":"文莱",
        "country_code":"BN",
        "areacode":"673",
        "className":"bn"
    },
    {
        "english_name":"Bolivia",
        "chinese_name":"玻利维亚",
        "country_code":"BO",
        "areacode":"591",
        "className":"bo"
    },
    {
        "english_name":"Caribisch Nederland",
        "chinese_name":"荷兰加勒比",
        "country_code":"BQ",
        "areacode":"599",
        "className":"bq"
    },
    {
        "english_name":"Brazil",
        "chinese_name":"巴西",
        "country_code":"BR",
        "areacode":"55",
        "className":"br"            
    },
    {
        "english_name":"Bahamas",
        "chinese_name":"巴哈马",
        "country_code":"BS",
        "areacode":"1242",
        "className":"bs"
    },
    {
        "english_name":"Bhutan",
        "chinese_name":"不丹",
        "country_code":"BT",
        "areacode":"975",
        "className":"bt"
    },
    {
        "english_name":"Botswana",
        "chinese_name":"博茨瓦纳",
        "country_code":"BW",
        "areacode":"267",
        "className":"bw"
    },
    {
        "english_name":"Belarus",
        "chinese_name":"白俄罗斯",
        "country_code":"BY",
        "areacode":"375",
        "className":"by"
    },
    {
        "english_name":"Belize",
        "chinese_name":"伯利兹",
        "country_code":"BZ",
        "areacode":"501",
        "className":"bz"
    },
    {
        "english_name":"Canada",
        "chinese_name":"加拿大",
        "country_code":"CA",
        "areacode":"1",
        "className":"ca"          
    },
    {
        "english_name":"Democratic Republic of theCongo",
        "chinese_name":"刚果民主共和国",
        "country_code":"CD",
        "areacode":"243",
        "className":"cd"
    },
    {
        "english_name":"Central African Republic",
        "chinese_name":"中非共和国",
        "country_code":"CF",
        "areacode":"236",
        "className":"cf"
    },
    {
        "english_name":"Republic Of The Congo",
        "chinese_name":"刚果共和国",
        "country_code":"CG",
        "areacode":"242",
        "className":"cg"
    },
    {
        "english_name":"Switzerland",
        "chinese_name":"瑞士",
        "country_code":"CH",
        "areacode":"41",
        "className":"ch"          
    },
    {
        "english_name":"Ivory Coast",
        "chinese_name":"象牙海岸",
        "country_code":"CI",
        "areacode":"225",
        "className":"ci"
    },
    {
        "english_name":"Cook Islands",
        "chinese_name":"库克群岛",
        "country_code":"CK",
        "areacode":"682",
        "className":"ck"
    },
    {
        "english_name":"Chile",
        "chinese_name":"智利",
        "country_code":"CL",
        "areacode":"56",
        "className":"cl"
    },
    {
        "english_name":"Cameroon",
        "chinese_name":"喀麦隆",
        "country_code":"CM",
        "areacode":"237",
        "className":"cm"
    },
    {
        "english_name":"China",
        "chinese_name":"中国",
        "country_code":"CN",
        "areacode":"86",
        "className":"cn"            
    },
    {
        "english_name":"Colombia",
        "chinese_name":"哥伦比亚",
        "country_code":"CO",
        "areacode":"57",
        "className":"co"          
    },
    {
        "english_name":"CostaRica",
        "chinese_name":"哥斯达黎加",
        "country_code":"CR",
        "areacode":"506",
        "className":"cr"
    },
    {
        "english_name":"Cuba",
        "chinese_name":"古巴",
        "country_code":"CU",
        "areacode":"53",
        "className":"cu"            
    },
    {
        "english_name":"Cape Verde",
        "chinese_name":"开普",
        "country_code":"CV",
        "areacode":"238",
        "className":"cv"
    },
    {
        "english_name":"Curacao",
        "chinese_name":"库拉索",
        "country_code":"CW",
        "areacode":"599",
        "className":"cw"
    },
    {
        "english_name":"Cyprus",
        "chinese_name":"塞浦路斯",
        "country_code":"CY",
        "areacode":"357",
        "className":"cy"
    },
    {
        "english_name":"Czech",
        "chinese_name":"捷克",
        "country_code":"CZ",
        "areacode":"420",
        "className":"cz"
    },
    {
        "english_name":"Germany",
        "chinese_name":"德国",
        "country_code":"DE",
        "areacode":"49",
        "className":"de"            
    },
    {
        "english_name":"Djibouti",
        "chinese_name":"吉布提",
        "country_code":"DJ",
        "areacode":"253",
        "className":"dj"
    },
    {
        "english_name":"Denmark",
        "chinese_name":"丹麦",
        "country_code":"DK",
        "areacode":"45",
        "className":"dk"            
    },
    {
        "english_name":"Dominica",
        "chinese_name":"多米尼加",
        "country_code":"DM",
        "areacode":"1767",
        "className":"dm"
    },
    {
        "english_name":"dominican republic",
        "chinese_name":"多米尼加共和国",
        "country_code":"DO",
        "areacode":"1809",
        "className":"do"
    },
    {
        "english_name":"Algeria",
        "chinese_name":"阿尔及利亚",
        "country_code":"DZ",
        "areacode":"213",
        "className":"dz"
    },
    {
        "english_name":"Ecuador",
        "chinese_name":"厄瓜多尔",
        "country_code":"EC",
        "areacode":"593",
        "className":"ec"
    },
    {
        "english_name":"Estonia",
        "chinese_name":"爱沙尼亚",
        "country_code":"EE",
        "areacode":"372",
        "className":"ee"
    },
    {
        "english_name":"Egypt",
        "chinese_name":"埃及",
        "country_code":"EG",
        "areacode":"20",
        "className":"eg"            
    },
    {
        "english_name":"Eritrea",
        "chinese_name":"厄立特里亚",
        "country_code":"ER",
        "areacode":"291",
        "className":"er"
    },
    {
        "english_name":"Spain",
        "chinese_name":"西班牙",
        "country_code":"ES",
        "areacode":"34",
        "className":"es"            
    },
    {
        "english_name":"Ethiopia",
        "chinese_name":"埃塞俄比亚",
        "country_code":"ET",
        "areacode":"251",
        "className":"et"
    },
    {
        "english_name":"Finland",
        "chinese_name":"芬兰",
        "country_code":"FI",
        "areacode":"358",
        "className":"fi"
    },
    {
        "english_name":"Fiji",
        "chinese_name":"斐济",
        "country_code":"FJ",
        "areacode":"679",
        "className":"fj"
    },
    {
        "english_name":"Micronesia",
        "chinese_name":"密克罗尼西亚",
        "country_code":"FM",
        "areacode":"691",
        "className":"fm"
    },
    {
        "english_name":"Faroe Islands",
        "chinese_name":"法罗群岛",
        "country_code":"FO",
        "areacode":"298",
        "className":"fo"
    },
    {
        "english_name":"France",
        "chinese_name":"法国",
        "country_code":"FR",
        "areacode":"33",
        "className":"fr"            
    },
    {
        "english_name":"Gabon",
        "chinese_name":"加蓬",
        "country_code":"GA",
        "areacode":"241",
        "className":"ga"
    },
    {
        "english_name":"United Kingdom",
        "chinese_name":"英国",
        "country_code":"GB",
        "areacode":"44",
        "className":"gb"            
    },
    {
        "english_name":"Grenada",
        "chinese_name":"格林纳达",
        "country_code":"GD",
        "areacode":"1473",
        "className":"gd"
    },
    {
        "english_name":"Georgia",
        "chinese_name":"格鲁吉亚",
        "country_code":"GE",
        "areacode":"995",
        "className":"ge"
    },
    {
        "english_name":"French Guiana",
        "chinese_name":"法属圭亚那",
        "country_code":"GF",
        "areacode":"594",
        "className":"gf"
    },
    {
        "english_name":"Ghana",
        "chinese_name":"加纳",
        "country_code":"GH",
        "areacode":"233",
        "className":"gh"
    },
    {
        "english_name":"Gibraltar",
        "chinese_name":"直布罗陀",
        "country_code":"GI",
        "areacode":"350",
        "className":"gi"
    },
    {
        "english_name":"Greenland",
        "chinese_name":"格陵兰岛",
        "country_code":"GL",
        "areacode":"299",
        "className":"gl"
    },
    {
        "english_name":"Gambia",
        "chinese_name":"冈比亚",
        "country_code":"GM",
        "areacode":"220",
        "className":"gm"
    },
    {
        "english_name":"Guinea",
        "chinese_name":"几内亚",
        "country_code":"GN",
        "areacode":"224",
        "className":"gn"
    },
    {
        "english_name":"Guadeloupe",
        "chinese_name":"瓜德罗普岛",
        "country_code":"GP",
        "areacode":"590",
        "className":"gp"
    },
    {
        "english_name":"Equatorial Guinea",
        "chinese_name":"赤道几内亚",
        "country_code":"GQ",
        "areacode":"240",
        "className":"gq"
    },
    {
        "english_name":"Greece",
        "chinese_name":"希腊",
        "country_code":"GR",
        "areacode":"30",
        "className":"gr"            
    },
    {
        "english_name":"Guatemala",
        "chinese_name":"瓜地马拉",
        "country_code":"GT",
        "areacode":"502",
        "className":"gt"
    },
    {
        "english_name":"Guam",
        "chinese_name":"关岛",
        "country_code":"GU",
        "areacode":"1671",
        "className":"gu"
    },
    {
        "english_name":"Guinea-Bissau",
        "chinese_name":"几内亚比绍共和国",
        "country_code":"GW",
        "areacode":"245",
        "className":"gw"
    },
    {
        "english_name":"Guyana",
        "chinese_name":"圭亚那",
        "country_code":"GY",
        "areacode":"592",
        "className":"gy"
    },
    {
        "english_name":"Hong Kong",
        "chinese_name":"中国香港",
        "country_code":"HK",
        "areacode":"852",
        "className":"hk"
    },
    {
        "english_name":"Honduras",
        "chinese_name":"洪都拉斯",
        "country_code":"HN",
        "areacode":"504",
        "className":"hn"
    },
    {
        "english_name":"Croatia",
        "chinese_name":"克罗地亚",
        "country_code":"HR",
        "areacode":"385",
        "className":"hr"
    },
    {
        "english_name":"Haiti",
        "chinese_name":"海地",
        "country_code":"HT",
        "areacode":"509",
        "className":"ht"
    },
    {
        "english_name":"Hungary",
        "chinese_name":"匈牙利",
        "country_code":"HU",
        "areacode":"36",
        "className":"hu"            
    },
    {
        "english_name":"Indonesia",
        "chinese_name":"印度尼西亚",
        "country_code":"ID",
        "areacode":"62",
        "className":"id"            
    },
    {
        "english_name":"Ireland",
        "chinese_name":"爱尔兰",
        "country_code":"IE",
        "areacode":"353",
        "className":"ie"
    },
    {
        "english_name":"Israel",
        "chinese_name":"以色列",
        "country_code":"IL",
        "areacode":"972",
        "className":"il"
    },
    {
        "english_name":"India",
        "chinese_name":"印度",
        "country_code":"IN",
        "areacode":"91",
        "className":"in"            
    },
    {
        "english_name":"Iraq",
        "chinese_name":"伊拉克",
        "country_code":"IQ",
        "areacode":"964",
        "className":"iq"
    },
    {
        "english_name":"Iran",
        "chinese_name":"伊朗",
        "country_code":"IR",
        "areacode":"98",
        "className":"ir"            
    },
    {
        "english_name":"Iceland",
        "chinese_name":"冰岛",
        "country_code":"IS",
        "areacode":"354",
        "className":"is"
    },
    {
        "english_name":"Italy",
        "chinese_name":"意大利",
        "country_code":"IT",
        "areacode":"39",
        "className":"it"            
    },
    {
        "english_name":"Jamaica",
        "chinese_name":"牙买加",
        "country_code":"JM",
        "areacode":"1876",
        "className":"jm"
    },
    {
        "english_name":"Jordan",
        "chinese_name":"约旦",
        "country_code":"JO",
        "areacode":"962",
        "className":"jo"
    },
    {
        "english_name":"Japan",
        "chinese_name":"日本",
        "country_code":"JP",
        "areacode":"81",
        "className":"jp"            
    },
    {
        "english_name":"Kenya",
        "chinese_name":"肯尼亚",
        "country_code":"KE",
        "areacode":"254",
        "className":"ke"
    },
    {
        "english_name":"Kyrgyzstan",
        "chinese_name":"吉尔吉斯斯坦",
        "country_code":"KG",
        "areacode":"996",
        "className":"kg"
    },
    {
        "english_name":"Cambodia",
        "chinese_name":"柬埔寨",
        "country_code":"KH",
        "areacode":"855",
        "className":"kh"
    },
    {
        "english_name":"Kiribati",
        "chinese_name":"基里巴斯",
        "country_code":"KI",
        "areacode":"686",
        "className":"ki"
    },
    {
        "english_name":"Comoros",
        "chinese_name":"科摩罗",
        "country_code":"KM",
        "areacode":"269",
        "className":"km"
    },
    {
        "english_name":"Saint Kitts and Nevis",
        "chinese_name":"圣基茨和尼维斯",
        "country_code":"KN",
        "areacode":"1869",
        "className":"kn"
    },
    {
        "english_name":"Korea Democratic Rep.",
        "chinese_name":"朝鲜",
        "country_code":"KP",
        "areacode":"850",
        "className":"kp"
    },
    {
        "english_name":"South Korea",
        "chinese_name":"韩国",
        "country_code":"KR",
        "areacode":"82",
        "className":"kr"            
    },
    {
        "english_name":"Kuwait",
        "chinese_name":"科威特",
        "country_code":"KW",
        "areacode":"965",
        "className":"kw"
    },
    {
        "english_name":"Cayman Islands",
        "chinese_name":"开曼群岛",
        "country_code":"KY",
        "areacode":"1345",
        "className":"ky"
    },
    {
        "english_name":"Kazakhstan",
        "chinese_name":"哈萨克斯坦",
        "country_code":"KZ",
        "areacode":"7",
        "className":"kz"           
    },
    {
        "english_name":"Laos",
        "chinese_name":"老挝",
        "country_code":"LA",
        "areacode":"856",
        "className":"la"
    },
    {
        "english_name":"Lebanon",
        "chinese_name":"黎巴嫩",
        "country_code":"LB",
        "areacode":"961",
        "className":"lb"
    },
    {
        "english_name":"Saint Lucia",
        "chinese_name":"圣露西亚",
        "country_code":"LC",
        "areacode":"1758",
        "className":"lc"
    },
    {
        "english_name":"Liechtenstein",
        "chinese_name":"列支敦士登",
        "country_code":"LI",
        "areacode":"423",
        "className":"li"
    },
    {
        "english_name":"Sri Lanka",
        "chinese_name":"斯里兰卡",
        "country_code":"LK",
        "areacode":"94",
        "className":"lk"            
    },
    {
        "english_name":"Liberia",
        "chinese_name":"利比里亚",
        "country_code":"LR",
        "areacode":"231",
        "className":"lr"
    },
    {
        "english_name":"Lesotho",
        "chinese_name":"莱索托",
        "country_code":"LS",
        "areacode":"266",
        "className":"ls"
    },
    {
        "english_name":"Lithuania",
        "chinese_name":"立陶宛",
        "country_code":"LT",
        "areacode":"370",
        "className":"lt"
    },
    {
        "english_name":"Luxembourg",
        "chinese_name":"卢森堡",
        "country_code":"LU",
        "areacode":"352",
        "className":"lu"
    },
    {
        "english_name":"Latvia",
        "chinese_name":"拉脱维亚",
        "country_code":"LV",
        "areacode":"371",
        "className":"lv"
    },
    {
        "english_name":"Libya",
        "chinese_name":"利比亚",
        "country_code":"LY",
        "areacode":"218",
        "className":"ly"
    },
    {
        "english_name":"Morocco",
        "chinese_name":"摩洛哥",
        "country_code":"MA",
        "areacode":"212",
        "className":"ma"
    },
    {
        "english_name":"Monaco",
        "chinese_name":"摩纳哥",
        "country_code":"MC",
        "areacode":"377",
        "className":"mc"
    },
    {
        "english_name":"Moldova",
        "chinese_name":"摩尔多瓦",
        "country_code":"MD",
        "areacode":"373",
        "className":"md"
    },
    {
        "english_name":"Montenegro",
        "chinese_name":"黑山",
        "country_code":"ME",
        "areacode":"382",
        "className":"me"
    },
    {
        "english_name":"Madagascar",
        "chinese_name":"马达加斯加",
        "country_code":"MG",
        "areacode":"261",
        "className":"mg"
    },
    {
        "english_name":"Marshall Islands",
        "chinese_name":"马绍尔群岛",
        "country_code":"MH",
        "areacode":"692",
        "className":"mh"
    },
    {
        "english_name":"Macedonia",
        "chinese_name":"马其顿",
        "country_code":"MK",
        "areacode":"389",
        "className":"mk"
    },
    {
        "english_name":"Mali",
        "chinese_name":"马里",
        "country_code":"ML",
        "areacode":"223",
        "className":"ml"
    },
    {
        "english_name":"Myanmar",
        "chinese_name":"缅甸",
        "country_code":"MM",
        "areacode":"95",
        "className":"mm"         
    },
    {

        "english_name":"Mongolia",
        "chinese_name":"蒙古",
        "country_code":"MN",
        "areacode":"976",
        "className":"mn"
    },
    {
        "english_name":"Macau",
        "chinese_name":"中国澳门",
        "country_code":"MO",
        "areacode":"853",
        "className":"mo"
    },
    {
        "english_name":"Mauritania",
        "chinese_name":"毛里塔尼亚",
        "country_code":"MR",
        "areacode":"222",
        "className":"mr"
    },
    {
        "english_name":"Montserrat",
        "chinese_name":"蒙特塞拉特岛",
        "country_code":"MS",
        "areacode":"1664",
        "className":"ms"
    },
    {
        "english_name":"Malta",
        "chinese_name":"马耳他",
        "country_code":"MT",
        "areacode":"356",
        "className":"mt"
    },
    {
        "english_name":"Mauritius",
        "chinese_name":"毛里求斯",
        "country_code":"MU",
        "areacode":"230",
        "className":"mu"
    },
    {
        "english_name":"Maldives",
        "chinese_name":"马尔代夫",
        "country_code":"MV",
        "areacode":"960",
        "className":"mv"
    },
    {
        "english_name":"Malawi",
        "chinese_name":"马拉维",
        "country_code":"MW",
        "areacode":"265",
        "className":"mw"
    },
    {
        "english_name":"Mexico",
        "chinese_name":"墨西哥",
        "country_code":"MX",
        "areacode":"52",
        "className":"mx"            
    },
    {
        "english_name":"Malaysia",
        "chinese_name":"马来西亚",
        "country_code":"MY",
        "areacode":"60",
        "className":"my"            
    },
    {
        "english_name":"Mozambique",
        "chinese_name":"莫桑比克",
        "country_code":"MZ",
        "areacode":"258",
        "className":"mz"
    },
    {
        "english_name":"Namibia",
        "chinese_name":"纳米比亚",
        "country_code":"NA",
        "areacode":"264",
        "className":"na"
    },
    {
        "english_name":"New Caledonia",
        "chinese_name":"新喀里多尼亚",
        "country_code":"NC",
        "areacode":"687",
        "className":"nc"
    },
    {
        "english_name":"Niger",
        "chinese_name":"尼日尔",
        "country_code":"NE",
        "areacode":"227",
        "className":"ne"
    },
    {
        "english_name":"Nigeria",
        "chinese_name":"尼日利亚",
        "country_code":"NG",
        "areacode":"234",
        "className":"ng"
    },
    {
        "english_name":"Nicaragua",
        "chinese_name":"尼加拉瓜",
        "country_code":"NI",
        "areacode":"505",
        "className":"ni"
    },
    {
        "english_name":"Netherlands",
        "chinese_name":"荷兰",
        "country_code":"NL",
        "areacode":"31",
        "className":"nl"            
    },
    {
        "english_name":"Norway",
        "chinese_name":"挪威",
        "country_code":"NO",
        "areacode":"47",
        "className":"no"            
    },
    {
        "english_name":"Nepal",
        "chinese_name":"尼泊尔",
        "country_code":"NP",
        "areacode":"977",
        "className":"np"
    },
    {
        "english_name":"Nauru",
        "chinese_name":"拿鲁岛",
        "country_code":"NR",
        "areacode":"674",
        "className":"nr"
    },
    {
        "english_name":"New Zealand",
        "chinese_name":"新西兰",
        "country_code":"NZ",
        "areacode":"64",
        "className":"nz"            
    },
    {
        "english_name":"Oman",
        "chinese_name":"阿曼",
        "country_code":"OM",
        "areacode":"968",
        "className":"om"
    },
    {
        "english_name":"Panama",
        "chinese_name":"巴拿马",
        "country_code":"PA",
        "areacode":"507",
        "className":"pa"
    },
    {
        "english_name":"Peru",
        "chinese_name":"秘鲁",
        "country_code":"PE",
        "areacode":"51",
        "className":"pe"            
    },
    {
        "english_name":"French Polynesia",
        "chinese_name":"法属波利尼西亚",
        "country_code":"PF",
        "areacode":"689",
        "className":"pf"
    },
    {
        "english_name":"Papua New Guinea",
        "chinese_name":"巴布亚新几内亚",
        "country_code":"PG",
        "areacode":"675",
        "className":"pg"
    },
    {
        "english_name":"Philippines",
        "chinese_name":"菲律宾",
        "country_code":"PH",
        "areacode":"63",
        "className":"ph"            
    },
    {
        "english_name":"Pakistan",
        "chinese_name":"巴基斯坦",
        "country_code":"PK",
        "areacode":"92",
        "className":"pk"            
    },
    {
        "english_name":"Poland",
        "chinese_name":"波兰",
        "country_code":"PL",
        "areacode":"48",
        "className":"pl"            
    },
    {
        "english_name":"Saint Pierreand Miquelon",
        "chinese_name":"圣彼埃尔和密克隆岛",
        "country_code":"PM",
        "areacode":"508",
        "className":"pm"
    },
    {
        "english_name":"Puerto Rico",
        "chinese_name":"波多黎各",
        "country_code":"PR",
        "areacode":"1787",
        "className":"pr"
    },
    {
        "english_name":"Portugal",
        "chinese_name":"葡萄牙",
        "country_code":"PT",
        "areacode":"351",
        "className":"pt"
    },
    {
        "english_name":"Palau",
        "chinese_name":"帕劳",
        "country_code":"PW",
        "areacode":"680",
        "className":"pw"
    },
    {
        "english_name":"Paraguay",
        "chinese_name":"巴拉圭",
        "country_code":"PY",
        "areacode":"595",
        "className":"py"
    },
    {
        "english_name":"Qatar",
        "chinese_name":"卡塔尔",
        "country_code":"QA",
        "areacode":"974",
        "className":"qa"
    },
    {
        "english_name":"Réunion Island",
        "chinese_name":"留尼汪",
        "country_code":"RE",
        "areacode":"262",
        "className":"re"
    },
    {
        "english_name":"Romania",
        "chinese_name":"罗马尼亚",
        "country_code":"RO",
        "areacode":"40",
        "className":"ro"            
    },
    {
        "english_name":"Serbia",
        "chinese_name":"塞尔维亚",
        "country_code":"RS",
        "areacode":"381",
        "className":"rs"
    },
    {
        "english_name":"Russia",
        "chinese_name":"俄罗斯",
        "country_code":"RU",
        "areacode":"7",
        "className":"ru"           
    },
    {
        "english_name":"Rwanda",
        "chinese_name":"卢旺达",
        "country_code":"RW",
        "areacode":"250",
        "className":"rw"
    },
    {
        "english_name":"Saudi Arabia",
        "chinese_name":"沙特阿拉伯",
        "country_code":"SA",
        "areacode":"966",
        "className":"sa"
    },
    {
        "english_name":"Solomon Islands",
        "chinese_name":"所罗门群岛",
        "country_code":"SB",
        "areacode":"677",
        "className":"sb"
    },
    {
        "english_name":"Seychelles",
        "chinese_name":"塞舌尔",
        "country_code":"SC",
        "areacode":"248",
        "className":"sc"
    },
    {
        "english_name":"Sudan",
        "chinese_name":"苏丹",
        "country_code":"SD",
        "areacode":"249",
        "className":"sd"
    },
    {
        "english_name":"Sweden",
        "chinese_name":"瑞典",
        "country_code":"SE",
        "areacode":"46",
        "className":"se"            
    },
    {
        "english_name":"Singapore",
        "chinese_name":"新加坡",
        "country_code":"SG",
        "areacode":"65",
        "className":"sg"            
    },
    {
        "english_name":"Slovenia",
        "chinese_name":"斯洛文尼亚",
        "country_code":"SI",
        "areacode":"386",
        "className":"si"
    },
    {
        "english_name":"Slovakia",
        "chinese_name":"斯洛伐克",
        "country_code":"SK",
        "areacode":"421",
        "className":"sk"
    },
    {
        "english_name":"Sierra Leone",
        "chinese_name":"塞拉利昂",
        "country_code":"SL",
        "areacode":"232",
        "className":"sl"
    },
    {
        "english_name":"San Marino",
        "chinese_name":"圣马力诺",
        "country_code":"SM",
        "areacode":"378",
        "className":"sm"
    },
    {
        "english_name":"Senegal",
        "chinese_name":"塞内加尔",
        "country_code":"SN",
        "areacode":"221",
        "className":"sn"
    },
    {
        "english_name":"Somalia",
        "chinese_name":"索马里",
        "country_code":"SO",
        "areacode":"252",
        "className":"so"
    },
    {
        "english_name":"Suriname",
        "chinese_name":"苏里南",
        "country_code":"SR",
        "areacode":"597",
        "className":"sr"
    },
    {
        "english_name":"Sao Tome and Principe",
        "chinese_name":"圣多美和普林西比",
        "country_code":"ST",
        "areacode":"239",
        "className":"st"
    },
    {
        "english_name":"ElSalvador",
        "chinese_name":"萨尔瓦多",
        "country_code":"SV",
        "areacode":"503",
        "className":"sv"
    },
    {
        "english_name":"Syria",
        "chinese_name":"叙利亚",
        "country_code":"SY",
        "areacode":"963",
        "className":"sy"
    },
    {
        "english_name":"Swaziland",
        "chinese_name":"斯威士兰",
        "country_code":"SZ",
        "areacode":"268",
        "className":"sz"
    },
    {
        "english_name":"Turksand Caicos Islands",
        "chinese_name":"特克斯和凯科斯群岛",
        "country_code":"TC",
        "areacode":"1649",
        "className":"tc"
    },
    {
        "english_name":"Chad",
        "chinese_name":"乍得",
        "country_code":"TD",
        "areacode":"235",
        "className":"td"
    },
    {
        "english_name":"Togo",
        "chinese_name":"多哥",
        "country_code":"TG",
        "areacode":"228",
        "className":"tg"
    },
    {
        "english_name":"Thailand",
        "chinese_name":"泰国",
        "country_code":"TH",
        "areacode":"66",
        "className":"th"           
    },
    {
        "english_name":"Tajikistan",
        "chinese_name":"塔吉克斯坦",
        "country_code":"TJ",
        "areacode":"992",
        "className":"tj"
    },
    {
        "english_name":"East Timor",
        "chinese_name":"东帝汶",
        "country_code":"TL",
        "areacode":"670",
        "className":"tl"
    },
    {
        "english_name":"Turkmenistan",
        "chinese_name":"土库曼斯坦",
        "country_code":"TM",
        "areacode":"993",
        "className":"tm"
    },
    {
        "english_name":"Tunisia",
        "chinese_name":"突尼斯",
        "country_code":"TN",
        "areacode":"216",
        "className":"tn"
    },
    {
        "english_name":"Tonga",
        "chinese_name":"汤加",
        "country_code":"TO",
        "areacode":"676",
        "className":"to"
    },
    {
        "english_name":"Turkey",
        "chinese_name":"土耳其",
        "country_code":"TR",
        "areacode":"90",
        "className":"tr"            
    },
    {
        "english_name":"Trinidadand Tobago",
        "chinese_name":"特立尼达和多巴哥",
        "country_code":"TT",
        "areacode":"1868",
        "className":"tt"
    },
    {
        "english_name":"Taiwan",
        "chinese_name":"中国台湾",
        "country_code":"TW",
        "areacode":"886",
        "className":"tw"
    },
    {
        "english_name":"Tanzania",
        "chinese_name":"坦桑尼亚",
        "country_code":"TZ",
        "areacode":"255",
        "className":"tz"
    },
    {
        "english_name":"Ukraine",
        "chinese_name":"乌克兰",
        "country_code":"UA",
        "areacode":"380",
        "className":"ua"
    },
    {
        "english_name":"Uganda",
        "chinese_name":"乌干达",
        "country_code":"UG",
        "areacode":"256",
        "className":"ug"
    },
    {
        "english_name":"United States",
        "chinese_name":"美国",
        "country_code":"US",
        "areacode":"1",
        "className":"us"           
    },
    {
        "english_name":"Uruguay",
        "chinese_name":"乌拉圭",
        "country_code":"UY",
        "areacode":"598",
        "className":"uy"
    },
    {
        "english_name":"Uzbekistan",
        "chinese_name":"乌兹别克斯坦",
        "country_code":"UZ",
        "areacode":"998",
        "className":"uz"
    },
    {
        "english_name":"Saint Vincent and The Grenadines",
        "chinese_name":"圣文森特和格林纳丁斯",
        "country_code":"VC",
        "areacode":"1784",
        "className":"vc"
    },
    {
        "english_name":"Venezuela",
        "chinese_name":"委内瑞拉",
        "country_code":"VE",
        "areacode":"58",
        "className":"ve"            
    },
    {
        "english_name":"VirginIslands,British",
        "chinese_name":"英属处女群岛",
        "country_code":"VG",
        "areacode":"1284",
        "className":"vg"
    },
    {
        "english_name":"Vietnam",
        "chinese_name":"越南",
        "country_code":"VN",
        "areacode":"84",
        "className":"vn"            
    },
    {
        "english_name":"Vanuatu",
        "chinese_name":"瓦努阿图",
        "country_code":"VU",
        "areacode":"678",
        "className":"vu"
    },
    {
        "english_name":"Samoa",
        "chinese_name":"萨摩亚",
        "country_code":"WS",
        "areacode":"685",
        "className":"ws"
    },
    {
        "english_name":"Yemen",
        "chinese_name":"也门",
        "country_code":"YE",
        "areacode":"967",
        "className":"ye"
    },
    {
        "english_name":"Mayotte",
        "chinese_name":"马约特",
        "country_code":"YT",
        "areacode":"269",
        "className":"yt"
    },
    {
        "english_name":"South Africa",
        "chinese_name":"南非",
        "country_code":"ZA",
        "areacode":"27",
        "className":"za"            
    },
    {
        "english_name":"Zambia",
        "chinese_name":"赞比亚",
        "country_code":"ZM",
        "areacode":"260",
        "className":"zm"
    },
    {
        "english_name":"Zimbabwe",
        "chinese_name":"津巴布韦",
        "country_code":"ZW",
        "areacode":"263",
        "className":"zw"
    }
]);
