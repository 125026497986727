angular.module('web.account', ['ngRoute', 'ngMessages', 'mg.bootstrap.alert', 'mg.bootstrap.dropdown', 'mg.widget.notify', 'mg.bootstrap.tooltip', 'mg.service.storage', 'help.interface'])

    .config(['$routeProvider', '$provide', '$httpProvider', function ($routeProvider, $provide, $httpProvider) {
        $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

        var param = function (obj) {
            var query = '', name, value, fullSubName, subName, subValue, innerObj, i;

            for (name in obj) {
                value = obj[name];

                if (value instanceof Array) {
                    for (i = 0; i < value.length; ++i) {
                        subValue = value[i];
                        fullSubName = name + '[' + i + ']';
                        innerObj = {};
                        innerObj[fullSubName] = subValue;
                        query += param(innerObj) + '&';
                    }
                } else if (value instanceof Object) {
                    for (subName in value) {
                        subValue = value[subName];
                        fullSubName = name + '[' + subName + ']';
                        innerObj = {};
                        innerObj[fullSubName] = subValue;
                        query += param(innerObj) + '&';
                    }
                } else if (value !== undefined && value !== null) {
                    query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
                }
            }

            return query.length ? query.substr(0, query.length - 1) : query;
        };

        // Override $http service's default transformRequest
        $httpProvider.defaults.transformRequest = [function (data) {
            return angular.isObject(data) && String(data) !== '[object File]' ? param(data) : data;
        }];

        $routeProvider.
            // 登录
            when('/signin', {
                templateUrl: 'tpl/login.htm',
                controllerAs: 'vm',
                controller: 'signinCtrl'
            }).
            // 扫码
            when('/scan', {
                templateUrl: 'tpl/scan.htm',
                controllerAs: 'vm',
                controller: 'scanCtrl'
            }).
            when('/signin/:state*', {
                templateUrl: 'tpl/login.htm',
                controllerAs: 'vm',
                controller: 'signinCtrl'
            }).
            when('/weixin', {
                templateUrl: 'tpl/weixin.htm',
                controller: 'weixinCtrl'
            }).
            //注册
            when('/signup', {
                templateUrl: 'tpl/register.htm',
                controllerAs: 'vm',
                controller: 'signupCtrl'
            }).
            //注册shopee
            when('/signupshopee', {
                templateUrl: 'tpl/shopee-register.htm',
                controllerAs: 'vm',
                controller: 'signupCtrl'
            }).
            //忘记密码
            when('/forgot', {
                templateUrl: 'tpl/forgot.htm',
                controllerAs: 'vm',
                controller: 'forgotCtrl'
            }).
            //忘记密码--新密码
            when('/forgot/newpwd/:mobile/:mobileCode', {
                templateUrl: 'tpl/forgot-newpwd.htm',
                controllerAs: 'vm',
                controller: 'forgotNewPwdCtrl'
            }).
            //忘记密码--成功
            when('/forgot/success', {
                templateUrl: 'tpl/forgot-success.htm',
                controller: 'forgotSuccessCtrl'
            }).
            //忘记密码--链接超期失效
            when('/forgot/expire', {
                templateUrl: 'tpl/forgot-expire.htm',
                controller: 'forgotExpireCtrl'
            }).
            //授权--登录
            when('/authlogin/:uid/:sign', {
                templateUrl: 'tpl/auth-login.htm',
                controllerAs: 'vm',
                controller: 'authloginCtrl'
            }).
            //授权--注册
            when('/authregister/:uid/:sign', {
                templateUrl: 'tpl/auth-register.htm',
                controllerAs: 'vm',
                controller: 'authregisterCtrl'
            }).
            when('/authregister/:uid/:sign/:msg', {
                templateUrl: 'tpl/auth-register.htm',
                controllerAs: 'vm',
                controller: 'authregisterCtrl'
            }).
            //授权--错误
            when('/autherror/:msg', {
                templateUrl: 'tpl/auth-error.htm',
                controllerAs: 'vm',
                controller: 'autherrorCtrl'
            }).
            otherwise({
                redirectTo: '/signin'
            });

        var inputDecoration = ["$delegate", "inputsWatcher", function ($delegate, inputsWatcher) {
            var directive = $delegate[0];
            var link = directive.link;

            function linkDecoration(scope, element, attrs, ctrls) {
                var handler, ngModel = ctrls[0];
                // By default model.$viewValue is equals to undefined
                if (attrs.type == "checkbox") {
                    inputsWatcher.registerInput(handler = function () {
                        var value = element[0].checked;
                        // By default element is not checked
                        if (value && ngModel.$viewValue !== value) {
                            ngModel.$setViewValue(value);
                        }
                    });
                } else if (attrs.type == "radio") {
                    inputsWatcher.registerInput(handler = function () {
                        var value = attrs.value;
                        // By default element is not checked
                        if (element[0].checked && ngModel.$viewValue !== value) {
                            ngModel.$setViewValue(value);
                        }
                    });
                } else {
                    inputsWatcher.registerInput(handler = function () {
                        var value = element.val();
                        // By default value is an empty string
                        if (ngModel) {
                            if ((ngModel.$viewValue !== undefined || value !== "") && ngModel.$viewValue !== value) {
                                ngModel.$setViewValue(value);
                            }
                        }
                    });
                }

                scope.$on("$destroy", function () {
                    inputsWatcher.unregisterInput(handler);
                });

                // Exec original `link()`
                link.apply(this, [].slice.call(arguments, 0));
            }

            /*
            directive.link = linkDecoration;
             */
            // So use `compile()` instead
            directive.compile = function compile(element, attrs, transclude) {
                return linkDecoration;
            };
            delete directive.link;

            return $delegate;
        }];

        $provide.decorator("inputDirective", inputDecoration);
    }])

    .factory("inputsWatcher", ["$interval", "$rootScope", function ($interval, $rootScope) {
        var INTERVAL_MS = 500;
        var promise;
        var handlers = [];
        function execHandlers() {
            for (var i = 0, l = handlers.length; i < l; i++) {
                handlers[i]();
            }
        }

        return {
            registerInput: function registerInput(handler) {
                if (handlers.push(handler) === 1) {
                    promise = $interval(execHandlers, INTERVAL_MS);
                }
            },
            unregisterInput: function unregisterInput(handler) {
                handlers.splice(handlers.indexOf(handler), 1);
                if (handlers.length === 0) {
                    $interval.cancel(promise);
                }
            },
            encrypt: function (word){
                var key = CryptoJS.enc.Utf8.parse("abcdefgabcdefg12");
                var srcs = CryptoJS.enc.Utf8.parse(word);
                var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
                return encrypted.toString();
            },
            setCookie: function (name, value, day) {
                if(day !== 0){     //当设置的时间等于0时，不设置expires属性，cookie在浏览器关闭后删除
                  var expires = day * 24 * 60 * 60 * 1000;
                  var date = new Date(+new Date()+expires);
                  document.cookie = name + "=" + escape(value) + ";expires=" + date.toUTCString();
                }else{
                  document.cookie = name + "=" + escape(value);
                }
            }
        };
    }])

    // 忘记密码 controller
    .controller('forgotCtrl', ['$scope', '$http', '$timeout', '$location', 'mgAlert', '$rootScope', 'mgWidgetNotify', 'PHONEliST','$modal','inputsWatcher', function ($scope, $http, $timeout, $location, mgAlert, $rootScope, notify,PHONEliST, $modal,inputsWatcher) {
        var vm = this;

        vm.data = {};

        vm.phoneList = PHONEliST;
        vm.selcted = vm.phoneList[42];

        $("body").removeClass('bg-forgot');
        var host = 'https://' + location.host;
        var defaultData = {
            img: host + '/register/imagecode?t=' + (+new Date())
        };
        vm.data = angular.copy(defaultData);

        // 手机号列表显示隐藏
        vm.phoneflag = false;

        vm.showPhone = function(){
            vm.phoneflag = !vm.phoneflag;
        };
        // 选择各国手机号
        vm.checkPhone = function(item) {
            vm.phoneflag = false;
            vm.selcted = item;
            console.log(vm.selcted.areacode);
        };

        //标识是否可发送
        vm.idle = true;
        vm.sendSMS = function () {
            var mobile = vm.data.mobile;
            if (!mobile) {
                return notify.set('请先填写手机号', { type: 'warn', position: 'top' });
            }
            if (vm.forgot.mobile.$error.mgValidMobile) {
                return notify.set('手机号已被使用', { type: 'warn', position: 'top' });
            }

            $modal.open({
                templateUrl: 'tpl/get-sms.htm',
                backdrop: 'static',
                // size: 'lg',
                controllerAs: 'vm',
                controller: ['$modalInstance', 'options', function ($modalInstance, options) {
                    var vm = this;
                    var host = 'https://' + location.host;
                    vm.img = host + '/register/phoneimgcode?t=' + (+new Date()) + '&areaCode=' + options.areacode;
                    vm.save = function () {
                        vm.fm.$setSubmitted();
                        if (vm.fm.$valid) {
                            vm.box = { type: 'loading' };
                            $http.post('/erp/account/send/smscode', { sendType: 'reset', mobile: options.mobile, phoneImgCode: vm.phoneImgCode,areaCode:options.areaCode }).success(function (res) {
                                vm.box = { type: 'close' };
                                if (res.success) {
                                    notify.set('发送成功', { type: 'success', position: 'top' });
                                    $modalInstance.close();
                                } else {
                                    notify.set(res.msg, { type: 'error', position: 'top' });
                                }
                            });
                        }
                    };
                    vm.refresh = function () {
                        vm.img = host + '/register/phoneimgcode?t=' + (+new Date());
                        // vm.fm.imgCode.focus();
                    };
                    vm.cancel = function (msg) {
                        $modalInstance.dismiss(msg);
                    };
                }],
                resolve: {
                    options: function () {
                        return {
                            mobile: mobile,
                            areaCode: vm.selcted.areacode
                        };
                    }
                }
            }).result.then(function () {
                startTimeCount();
            });

        };
        //g开始60秒倒计时
        function startTimeCount() {
            vm.idle = false;
            vm.second = 61;
            timeCount();
            function timeCount() {
                vm.second--;
                if (vm.second > 0) {
                    $timeout(timeCount, 1000);
                } else {
                    vm.idle = true;
                }
            }
        }

        vm.submitForgot = function () {
            var forgot = vm.forgot;
            if (forgot.$valid) {
                $http.get('/register/createNewPWD/checkMobile', { params: vm.data }).then(function (response) {
                    var res = response.data;
                    if (res.success) {
                        inputsWatcher.setCookie('chooseToken',' ',-1);
                        $timeout(function () {
                            $location.url('/forgot/newpwd/' + vm.data.mobile + '/' + vm.data.mobileCode);
                        }, 20);
                    } else {
                        mgAlert.error(res.msg);
                    }
                });
            }
        };

    }])

    //忘记密码--超期
    .controller('forgotExpireCtrl', function () {

    })

    //忘记密码--成功
    .controller('forgotSuccessCtrl', function () {

    })

    //忘记密码--新密码
    .controller('forgotNewPwdCtrl', ['$scope', '$routeParams', '$http', '$location', '$timeout', 'mgAlert','inputsWatcher', function ($scope, $routeParams, $http, $location, $timeout, mgAlert,inputsWatcher) {
        var vm = this;

        vm.data = {
            mobile: $routeParams.mobile,
            mobileCode: $routeParams.mobileCode
        };

        vm.createNewPwd = function () {
            var data={
                id: vm.data.id,
                code: vm.data.mobileCode,
                password: vm.data.password
            };
            if(window.CryptoJS){
                data.password= inputsWatcher.encrypt(vm.data.password);
            }
            if (vm.signup.$valid) {
                $http.post('/register/createNewPWD/mobile', data).success(function (res) {
                    if (res.success) {
                        $location.url('/forgot/success');
                    } else {
                        mgAlert.error('对不起，密码重置失败', res.msg || '您可以重试，或者重新找回密码');
                    }
                });
            }
        };

        $http.get('/register/createNewPWD/checkMobile', { params: vm.data }).then(function (response) {
            var res = response.data;
            if (res.success) {
                if (res.data.state == 'expire') {
                    $location.url('/forgot/expire');
                } else {
                    vm.data.loginName = res.data.loginName;
                    vm.data.id = res.data.id;
                }
            } else {
                mgAlert.error(res.msg);
            }
        });
    }])

    // 微信登录--暂时没用
    .controller('weixinCtrl', ['$http', 'mgAlert', function ($http, mgAlert) {

        $http.get('/erp/account/login/getWxQrCodeConfig', { cache: true }).success(function (res) {
            if (res.success) {
                var config = res.data.config;
                new WxLogin({
                    id: "wxlogin",
                    appid: config.appid,
                    scope: config.scope,
                    redirect_uri: config.redirectUrl,
                    state: config.state,
                    style: "",
                    href: ""
                });
            } else {
                mgAlert.error(res.msg);
            }
        });
    }])

    // 登录
    .controller('signinCtrl', ['$scope', '$routeParams', '$q', '$http', 'mgWidgetNotify', 'storage','inputsWatcher',function ($scope, $routeParams, $q, $http, notify, storage,inputsWatcher) {
        $("body").removeClass('bg-forgot');
        var vm = this;

        // if (window.location.href.includes('//www.mangoerp.com/login#/signin')) {
        //   window.location.href = 'https://www.mangoerp.com/erp/#/erp/dashboard3';
        // }

        $scope.env = angular.env;
        $scope.submitLogin = function () {
            var signin = $scope.signin;
            if (signin.$valid) {
                var data = {
                    username: $scope.username,
                    password: $scope.password,
                    rememberMe: $scope.rememberMe
                };
                if(window.CryptoJS){
                    data = {
                        username:  inputsWatcher.encrypt($scope.username),
                        password: inputsWatcher.encrypt($scope.password),
                        rememberMe: $scope.rememberMe
                    };
                }
                $http.post('/login/ajax', data).success(function (res) {
                    if (res.success) {
                        if($('#mangoerpobj')&&getCookie('chooseToken')){
                            $('#mangoerpobj').attr('href','https://xuanpin.mangoerp.com/#/home?paramsobj='+getCookie('chooseToken'));
                        }
                        localStorage.setItem("isNewWms", getCookie('isNewWms'));
                        console.log(localStorage.getItem('isNewWms'), '666');
                        location.href = $routeParams.state || '/erp/#/erp/dashboard3';
                    } else {
                        notify.set(res.msg, { type: 'error', position: 'top' });
                    }
                });
            }
        };

        // 登录页广告连接
        $http.get('/admin/advertisting/getloginpagelist', { params: { adLocation: 'LOGIN_PAGE' } }).then(function (response) {
            var res = response.data;
            if (res.success) {
                vm.adList = _.map(res.data.list, function (item, index) {
                    return {
                        id:item.id,
                        title: item.title,
                        url: item.advertisingUrl,
                        imgUrl: item.imageUrl
                    };
                });
            }
        });
        //登录页点击量
        vm.closelogin = function( add ){
            $http.put('/admin/advertisting/clickcount', {id: add},
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
            ).then(function (response) {
                var res = response.data;
                if (res.success) {
                }
            });
        };

        function getCookie(key) {
            var arr = document.cookie.split(/;\s*/);
            var finder = _.find(arr, function (str) {
                return str.split('=')[0] === key;
            });
            if (finder) {
                return finder.split('=')[1];
            } else {
                return '';
            }
        }

        var _fetch = window.fetch;
        if (_fetch && location.protocol === 'http:' && location.host === 'www.mangoerp.com') {
            var start = Date.now();
            $q.all([_fetch('https://www.mangoerp.com/index', { method: 'HEAD' }), _fetch('https://asset.mangoerp.com/index.htm', { method: 'HEAD' }), _fetch('https://cache.mangoerp.com/index', { method: 'HEAD' })]).then(function (promises) {
                var ms = Date.now() - start;
                if (promises[0].ok && promises[1].ok && ms < 1200) {
                    document.cookie = 'https=ok;path=/;domain=.mangoerp.com;expires=' + (new Date(Date.now() + 60e3 * 60 * 12)).toGMTString();
                    console.log('https: ' + ms + 'ms');
                }
            });
        }

        // 系统首页广告登录后重新显示
        storage.set('erp.dashboard3',{});
        // 系统首页侧边栏广告登录后重新显示
        localStorage['slide-menu'] = JSON.stringify([ { state: true }, { state: true }, { state: true }, { state: true }, { state: true }, { state: true } ]);
    }])
    // 注册
    .controller('signupCtrl', ['$scope', '$timeout', '$http', 'mgWidgetNotify','PHONEliST','$modal','inputsWatcher', function ($scope, $timeout, $http, notify, PHONEliST,$modal,inputsWatcher) {
        var vm = this;
        $(".light-login").removeClass('bg-none');
        vm.phoneList = PHONEliST;
        vm.selectList = PHONEliST; // 新增一个选择时的复印件
        vm.selcted = vm.phoneList[42];
        $("body").removeClass('bg-forgot');
        var host = 'https://' + location.host;
        var defaultData = {
            img: host + '/register/imagecode?t=' + (+new Date())
        };
        vm.data = angular.copy(defaultData);

        // 手机号列表显示隐藏
        vm.phoneflag = false;

        vm.showPhone = function(){
            vm.phoneflag = !vm.phoneflag;
        };
        // 新增input模糊搜索
        vm.phoneChange = function (item) {
            console.log('item', item);
            vm.selectList = [];
            _.each(vm.phoneList, function (list) {
                var keyName = list.english_name + '(' + list.chinese_name + ')';
                if (keyName.includes(item)) {
                    console.log(keyName);
                    vm.selectList.push(list);
                }
            });
        };

        // 阻止事件冒泡
        $('.dropdown-menu .search-input').click(function(e) {
            e.stopPropagation();
        });

        // 选择各国手机号
        vm.checkPhone = function(item) {
            vm.phoneflag = false;
            vm.selcted = item;
        };

        // 是否同意服务协议
        vm.agreementChecked = false;

        // var hash = location.hash;
        // if (hash.indexOf('=') >= 0) {
        //     vm.linkCode = hash.split('=')[1];
        // }
        var hash = location.hash.split('&');
        _.each(hash, function (ele) {
            if (ele.indexOf('linkCode') >= 0) {
                vm.linkCode = ele.split('=')[1];
            }
            if (ele.indexOf('linkType') >= 0) {
                vm.linkType = ele.split('=')[1];
            }
        });
        // 登录页广告连接
        $http.get('/admin/advertisting/getloginpagelist', { params: { adLocation: 'LOGIN_PAGE' } }).then(function (response) {
            var res = response.data;
            if (res.success) {
                vm.adList = _.map(res.data.list, function (item, index) {
                    return {
                        id:item.id,
                        title: item.title,
                        url: item.advertisingUrl,
                        imgUrl: item.imageUrl
                    };
                });
            }
        });
        //登录页点击量
        vm.closelogin = function( add ){
            $http.put('/admin/advertisting/clickcount', {id: add},
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
            ).then(function (response) {
                var res = response.data;
                if (res.success) {
                }
            });
        };

        //标识是否可发送
        vm.idle = true;
        vm.sendSMS = function () {
            var mobile = vm.data.mobile;
            if (!mobile) {
                return notify.set('请先填写手机号', { type: 'warn', position: 'top' });
            }
            if (vm.signup.mobile.$error.mgValidMobile) {
                return notify.set('手机号已被使用', { type: 'warn', position: 'top' });
            }
            $modal.open({
                templateUrl: 'tpl/get-sms.htm',
                backdrop: 'static',
                // size: 'lg',
                controllerAs: 'vm',
                controller: ['$modalInstance', 'options', function ($modalInstance, options) {
                    var vm = this;
                    var host = 'https://' + location.host;
                    vm.img = host + '/register/phoneimgcode?t=' + (+new Date()) + '&areaCode=' + options.areacode;
                    vm.save = function () {
                        vm.fm.$setSubmitted();
                        if (vm.fm.$valid) {
                            vm.box = { type: 'loading' };
                            console.log(options,"zczc");

                            $http.post('/erp/account/send/smscode', { sendType: 'register', mobile: options.mobile, phoneImgCode: vm.phoneImgCode, areaCode: options.areaCode }).success(function (res) {
                                vm.box = { type: 'close' };
                                if (res.success) {
                                    notify.set('发送成功', { type: 'success', position: 'top' });
                                    $modalInstance.close();
                                } else {
                                    notify.set(res.msg, { type: 'error', position: 'top' });
                                }
                            });
                        }
                    };
                    vm.refresh = function () {
                        vm.img = host + '/register/phoneimgcode?t=' + (+new Date());
                        // vm.fm.imgCode.focus();
                    };
                    vm.cancel = function (msg) {
                        $modalInstance.dismiss(msg);
                    };
                }],
                resolve: {
                    options: function () {
                        return {
                            mobile: mobile,
                            areaCode: vm.selcted.areacode
                        };
                    }
                }
            }).result.then(function () {
                startTimeCount();
            });
        };

        vm.reset = function () {
            _.each(vm.signup.$error.required, function (item) {
                item.$touched = false;
            });
            _.each(vm.signup.$$success.maxlength, function (item) {
                item.$touched = false;
            });
            vm.signup.$submitted = false;
            vm.data = angular.copy(defaultData);
        };

        /**
         * 查看注册服务协议
         * @author 陈威 <490945932@qq.com>
         * @time 2020年05月08日 13:58:42 星期五
         */
        vm.openAgreement = function() {
            $modal.open({
                templateUrl: 'tpl/register-agreement.htm',
                // backdrop: 'static',
                size: 'lg',
                controllerAs: 'vm',
                controller: ['$modalInstance', 'options', function ($modalInstance, options) {
                    var vm = this;
                    vm.cancel = function (msg) {
                        $modalInstance.dismiss(msg);
                    };
                }],
                resolve: {
                    options: function () {
                        return {

                        };
                    }
                }
            }).result.then(function (data) {
                console.log('data', data);
            });
        };

        vm.submitRegister = function (noRedirect) {

            vm.signup.$setSubmitted();
            if (vm.signup.$valid) {
                if (!vm.agreementChecked) {
                    notify.set('请阅读并同意协议', { type: 'warn', position: 'top' });
                    return;
                }
                var data = {
                    loginName: vm.data.name,
                    plainPassword: vm.data.password,
                    confirmPassword: vm.data.repeatPassword,
                    mobile: vm.data.mobile,
                    mobileCode: vm.data.mobileCode,
                    imgCode: vm.data.code,
                    areacode: vm.selcted.areacode
                };
                console.log(data);
                if(window.CryptoJS){
                    data.loginName= inputsWatcher.encrypt(vm.data.name);
                    data.plainPassword= inputsWatcher.encrypt(vm.data.password);
                    data.confirmPassword= inputsWatcher.encrypt(vm.data.repeatPassword);
                }
                if (vm.linkCode) {
                    data['linkCode'] = vm.linkCode;
                }
                if (vm.linkType) {
                    data['linkType'] = vm.linkType;
                }

                $http.post('/register/ajax', data).success(function (res) {
                    if (res.success) {
                        notify.set('注册成功', { type: 'success', position: 'top' });
                        if (noRedirect) {
                            vm.reset();
                        } else {
                            location.href = '/login#/signin';
                        }
                    } else {
                        notify.set(res.msg, { type: 'error', position: 'top' });
                    }
                });
            }
        };
        vm.refresh = function () {
            vm.data.img = host + '/register/imagecode?t=' + (+new Date());
            // vm.signup.imgCode.focus();
        };

        //g开始60秒倒计时
        function startTimeCount() {
            vm.idle = false;
            vm.second = 61;
            timeCount();

            function timeCount() {
                vm.second--;
                if (vm.second > 0) {
                    $timeout(timeCount, 1000);
                } else {
                    vm.idle = true;
                }
            }
        }
    }])

    // 授权登录
    .controller('authloginCtrl', ['$scope', '$routeParams', '$http', 'mgWidgetNotify', function ($scope, $routeParams, $http, notify) {
        var vm = this;

        vm.data = {
            uid: $routeParams.uid,
            sign: $routeParams.sign,
        };
        $scope.submitLogin = function () {
            var signin = $scope.signin;
            if (signin.$valid) {
                var data = {
                    uid: vm.data.uid,
                    sign: vm.data.sign,
                    username: $scope.username,
                    password: $scope.password
                };
                $http.post('/login/loginAndBind', data).success(function (res) {
                    if (res.success) {
                        location.href = '/erp/#/erp/dashboard3';
                    } else {
                        notify.set(res.msg, { type: 'error', position: 'top' });
                    }
                });
            }
        };
    }])

    // 授权注册
    .controller('authregisterCtrl', ['$scope', '$routeParams', '$timeout', '$http', 'mgWidgetNotify', '$modal', function ($scope, $routeParams, $timeout, $http, notify, $modal) {
        var vm = this;

        var host = 'https://' + location.host;
        vm.data = {
            uid: $routeParams.uid,
            sign: $routeParams.sign,
            img: host + '/register/imagecode?t=' + (+new Date())
        };

        // 是否同意服务协议
        vm.agreementChecked = false;

        if ($routeParams.msg) {
            notify.set($routeParams.msg, { type: 'warn', position: 'top', sticky: true });
        }

        //标识是否可发送
        vm.idle = true;
        vm.sendSMS = function () {
            var mobile = vm.data.mobile;
            if (!mobile) {
                return notify.set('请先填写手机号', { type: 'warn', position: 'top' });
            }
            if (vm.signup.mobile.$error.mgValidMobile) {
                return notify.set('手机号已被使用', { type: 'warn', position: 'top' });
            }
            $modal.open({
                templateUrl: 'tpl/get-sms.htm',
                backdrop: 'static',
                // size: 'lg',
                controllerAs: 'vm',
                controller: ['$modalInstance', 'options', function ($modalInstance, options) {
                    var vm = this;
                    var host = 'https://' + location.host;
                    vm.img = host + '/register/phoneimgcode?t=' + (+new Date());
                    vm.save = function () {
                        vm.fm.$setSubmitted();
                        if (vm.fm.$valid) {
                            vm.box = { type: 'loading' };
                            $http.post('/erp/account/send/smscode', { sendType: 'register', mobile: options.mobile, phoneImgCode: vm.phoneImgCode,areaCode:options.areaCode }).success(function (res) {
                                vm.box = { type: 'close' };
                                if (res.success) {
                                    notify.set('发送成功', { type: 'success', position: 'top' });
                                    $modalInstance.close();
                                } else {
                                    notify.set(res.msg, { type: 'error', position: 'top' });
                                }
                            });
                        }
                    };
                    vm.refresh = function () {
                        vm.img = host + '/register/phoneimgcode?t=' + (+new Date());
                        // vm.fm.imgCode.focus();
                    };
                    vm.cancel = function (msg) {
                        $modalInstance.dismiss(msg);
                    };
                }],
                resolve: {
                    options: function () {
                        return {
                            mobile: mobile,
                            areaCode: vm.selcted.areacode

                        };
                    }
                }
            }).result.then(function () {
                startTimeCount();
            });
        };

        /**
         * 查看注册服务协议
         * @author 陈威 <490945932@qq.com>
         * @time 2020年05月08日 13:58:42 星期五
         */
        vm.openAgreement = function() {
            $modal.open({
                templateUrl: 'tpl/register-agreement.htm',
                // backdrop: 'static',
                size: 'lg',
                controllerAs: 'vm',
                controller: ['$modalInstance', 'options', function ($modalInstance, options) {
                    var vm = this;
                    vm.cancel = function (msg) {
                        $modalInstance.dismiss(msg);
                    };
                }],
                resolve: {
                    options: function () {
                        return {

                        };
                    }
                }
            }).result.then(function (data) {
                console.log('data', data);
            });
        };

        vm.submitRegister = function () {
            vm.signup.$setSubmitted();
            if (vm.signup.$valid) {
                if (!vm.agreementChecked) {
                    notify.set('请阅读并同意协议', { type: 'warn', position: 'top' });
                    return;
                }
                var data = {
                    uid: vm.data.uid,
                    sign: vm.data.sign,
                    loginName: vm.data.name,
                    plainPassword: vm.data.password,
                    confirmPassword: vm.data.repeatPassword,
                    mobile: vm.data.mobile,
                    mobileCode: vm.data.mobileCode,
                    imgCode: vm.data.code
                };
                $http.post('/register/registerAndBind', data).success(function (res) {
                    if (res.success) {
                        notify.set('注册成功', { type: 'success', position: 'top' });
                        location.href = '/erp/#/erp/dashboard3';
                    } else {
                        notify.set(res.msg, { type: 'error', position: 'top' });
                    }
                });
            }
        };
        vm.refresh = function () {
            vm.data.img = host + '/register/imagecode?t=' + (+new Date());
            // vm.signup.imgCode.focus();
        };

        //g开始60秒倒计时
        function startTimeCount() {
            vm.idle = false;
            vm.second = 61;
            timeCount();

            function timeCount() {
                vm.second--;
                if (vm.second > 0) {
                    $timeout(timeCount, 1000);
                } else {
                    vm.idle = true;
                }
            }
        }
    }])

    // 授权失败
    .controller('autherrorCtrl', ['$routeParams', function ($routeParams) {
        var vm = this;
        vm.msg = $routeParams.msg;
    }])

    .factory('RemoteValid', ['$http', '$q', function ($http, $q) {
        return {
            uname: function (name) {
                var deferrerd = $q.defer();
                $http.get('/register/checkLoginName?loginName=' + name).success(function (data) {
                    deferrerd.resolve(data);
                });
                return deferrerd.promise;
            },
            imgCode: function (code) {
                var deferrerd = $q.defer();
                $http.get('/register/checkimgcode?imgCode=' + code).success(function (data) {
                    deferrerd.resolve(data);
                });
                return deferrerd.promise;

            },
            phoneImgCode: function (code) {
                var deferrerd = $q.defer();
                $http.get('/register/checkphoneimgcode?phoneImgCode=' + code).success(function (data) {
                    deferrerd.resolve(data);
                });
                return deferrerd.promise;

            },
            //手机验证码校验
            mobile: function (mobile) {
                var deferrerd = $q.defer();
                $http.get('/register/checkMobile?mobile=' + mobile).success(function (data) {
                    deferrerd.resolve(data);
                });
                return deferrerd.promise;
            },
            //手机验证码校验
            mobileCode: function (mobile, code) {
                var deferrerd = $q.defer();
                $http.get('/register/checkMobileCode?mobileCode=' + code + '&mobile=' + mobile).success(function (data) {
                    deferrerd.resolve(data);
                });
                return deferrerd.promise;
            },
            email: function (email) {
                var deferrerd = $q.defer();
                $http.get('/register/checkemail?email=' + email).success(function (data) {
                    deferrerd.resolve(data);
                });
                return deferrerd.promise;
            }
        };
    }])

    // directives
    // 重复密码检验
    .directive('repeatpwd', function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                // 最后检验
                ctrl.$parsers.push(function (viewValue) {
                    if (!ctrl.$error.required) {
                        var pwd1 = attrs.repeatpwd;
                        if (viewValue === pwd1) {
                            ctrl.$setValidity('repeatpwd', true);
                            return viewValue;
                        } else {
                            ctrl.$setValidity('repeatpwd', false);
                            return undefined;
                        }
                    } else {
                        ctrl.$setValidity('repeatpwd', true);
                        return viewValue;
                    }
                });

            }
        };
    })
    // s校验字符串字母数字下划线
    .directive('mgAsCode', function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                var reg = /^\w+$/;
                ctrl.$parsers.push(function (value) {
                    if (!ctrl.$error.required) {
                        if (value.match(reg)) {
                            ctrl.$setValidity('mgAsCode', true);
                        } else {
                            ctrl.$setValidity('mgAsCode', false);
                        }
                    } else {
                        ctrl.$setValidity('mgAsCode', true);
                    }
                    return value;
                });
            }
        };
    })
    .directive('mgAsPwd', function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                console.log('ctrl', ctrl);
                var reg = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{8,20}$/;
                // var reg = /^\w+$/;
                ctrl.$parsers.push(function (value) {
                    if (!value) {
                        return;
                    }
                    if (!ctrl.$error.required) {
                        if (value.match(reg)) {
                            ctrl.$setValidity('mgAsPwd', true);
                        } else {
                            ctrl.$setValidity('mgAsPwd', false);
                        }
                    } else {
                        ctrl.$setValidity('mgAsPwd', true);
                    }
                    return value;
                });
            }
        };
    })
    // min-length
    .directive('mgMinlength', function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                var len = +attrs.mgMinlength;
                ctrl.$parsers.push(function (value) {
                    if (!ctrl.$error.required) {
                        if (value.length >= len) {
                            ctrl.$setValidity('mgMinlength', true);
                        } else {
                            ctrl.$setValidity('mgMinlength', false);
                        }
                    } else {
                        ctrl.$setValidity('mgMinlength', true);
                    }
                    return value;
                });
            }
        };
    })

    // 校验用户名是否已注册
    .directive('mgValidUname', ['RemoteValid', function (RemoteValid) {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$parsers.push(function (value) {
                    if (value) {
                        RemoteValid.uname(value).then(function (data) {
                            if (data == '"true"') {
                                ctrl.$setValidity('mgValidUname', true);
                            } else {
                                ctrl.$setValidity('mgValidUname', false);
                            }
                        });
                        return value;
                    } else {
                        ctrl.$setValidity('mgValidUname', true);
                        return value;
                    }
                });
            }
        };
    }])

    // 手机号
    .directive('mgValidMobile', ['RemoteValid', function (RemoteValid) {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$parsers.push(function (value) {
                    if (value) {
                        if (value.length === 11) {
                            RemoteValid.mobile(value).then(function (res) {
                                if (res.success) {
                                    ctrl.$setValidity('mgValidMobile', true);
                                } else {
                                    ctrl.$setValidity('mgValidMobile', false);
                                }
                            });
                        }
                        return value;
                    } else {
                        ctrl.$setValidity('mgValidMobile', true);
                        return value;
                    }
                });
            }
        };
    }])

    // 手机验证码
    .directive('mgValidMobileCode', ['RemoteValid', function (RemoteValid) {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$parsers.push(function (value) {
                    var mobile = scope.vm.data.mobile;
                    if (mobile && value) {
                        if (value.length === 6) {
                            RemoteValid.mobileCode(mobile, value).then(function (res) {
                                if (res.success) {
                                    ctrl.$setValidity('mgValidMobileCode', true);
                                } else {
                                    ctrl._errorMsg = res.msg || res.code || '未知错误';
                                    ctrl.$setValidity('mgValidMobileCode', false);
                                }
                            });
                        }
                        return value;
                    } else {
                        ctrl.$setValidity('mgValidMobileCode', true);
                        return value;
                    }
                });
            }
        };
    }])

    .directive('mgValidEmail', ['RemoteValid', function (RemoteValid) {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$parsers.push(function (value) {
                    if (value) {
                        RemoteValid.email(value).then(function (data) {
                            if (data == '"true"') {
                                ctrl.$setValidity('mgValidEmail', true);
                            } else {
                                ctrl.$setValidity('mgValidEmail', false);
                            }
                        });
                        return value;
                    } else {
                        ctrl.$setValidity('mgValidEmail', true);
                        return value;
                    }
                });
            }
        };
    }])
    .directive('mgValidCode', ['RemoteValid', function (RemoteValid) {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$parsers.push(function (value) {
                    if (value) {
                        RemoteValid.imgCode(value).then(function (data) {
                            if (data == '"true"') {
                                ctrl.$setValidity('mgValidCode', true);
                            } else {
                                ctrl.$setValidity('mgValidCode', false);
                            }
                        });
                        return value;
                    } else {
                        ctrl.$setValidity('mgValidCode', true);
                        return value;
                    }
                });
            }
        };
    }])
    .directive('mgValidPhoneCode', ['RemoteValid', function (RemoteValid) {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$parsers.push(function (value) {
                    if (value) {
                        RemoteValid.phoneImgCode(value).then(function (data) {
                            if (data == '"true"') {
                                ctrl.$setValidity('mgValidPhoneCode', true);
                            } else {
                                ctrl.$setValidity('mgValidPhoneCode', false);
                            }
                        });
                        return value;
                    } else {
                        ctrl.$setValidity('mgValidPhoneCode', true);
                        return value;
                    }
                });
            }
        };
    }])
    // 扫码
    .controller('scanCtrl', ['$scope', '$routeParams', '$q', '$http', 'mgWidgetNotify', 'storage','inputsWatcher', 'mgAlert', 'PHONEliST', '$modal', '$timeout',function ($scope, $routeParams, $q, $http, notify, storage,inputsWatcher, mgAlert, PHONEliST, $modal, $timeout) {
        // $("body").removeClass('bg-forgot');
        var vm = this;
        // 选择登陆账号
        vm.userVisible = false;
        // vm.isShow = false;
        // 扫码登录
        vm.scanVisible = true;
        // 用户绑定
        vm.userBinding = false;
        // 遮罩显示与隐藏
        vm.maskVisible = false;
        vm.maskTestVisible = false;
        // 二维码
        vm.scanCode = '';
        // 获取到的用户信息
        vm.expire = '';
        vm.sceneStr = '';
        vm.openid = '';
        vm.sign = '';
        vm.adList = [];
        // 当前选择账号
        vm.selectIndex = 0;
        // 用户信息
        vm.accountList = [];
        vm.boxOne = true;
        vm.boxTwo = false;
        vm.boxThree = false;
        // 用户信息
        vm.userInfo = {
            username: '',
            password: '',
        };
        // 用户绑定显示与隐藏
        vm.boxOneError = true;
        vm.boxTwoError = false;
        // 选择用户已停用提示
        vm.selectHeadTwo = false;
        // 获取二维码
        getCode();
        // 获取二维码
        function getCode() {
            $http.get('/erp/wechat/createLoginQrcode').then(function (response) {
                var res = response.data;
                if (res.success) {
                    // vm.isShow = true;
                    vm.scanCode = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + res.data.ticket;
                    vm.expire = res.data.expire;
                    vm.sceneStr = res.data.sceneStr;
                    // 每分钟判断二维码是否过期
                    var timers = setInterval(function () {
                        var date = new Date().getTime();
                        console.log(date, vm.expire, 'vm.expire');
                        if (date > vm.expire) {
                            // 遮罩显示与隐藏
                            $timeout(function () {
                                vm.maskVisible = true;
                                vm.maskTestVisible = true;
                                console.log(vm.maskTestVisible, 'vm.maskTestVisible');
                                console.log(vm.maskVisible, 'vm.maskVisible');
                            }, 10);
                            clearInterval(timer);
                            clearInterval(timers);

                        }
                    }, 60000);
                    // 每3秒触发一次接口 验证用户是否扫码
                    var timer = setInterval(function () {
                        $http.post('/login/loginCheck', {sceneStr: vm.sceneStr}).then(function (response) {
                            var res = response.data;
                            if (res.success) {
                                if (res.data.scan) {
                                    console.log('已经扫码');
                                    vm.openid = res.data.info.openid;
                                    vm.sign = res.data.sign;
                                    vm.accountList = res.data.info.accountList;
                                    if (!vm.accountList.length) {
                                        // 选择登陆账号
                                        vm.userVisible = false;
                                        // 扫码登录
                                        vm.scanVisible = false;
                                        // 用户绑定
                                        vm.userBinding = true;
                                        vm.backChange();
                                    }  else if (vm.accountList.length == 1) {
                                        // 当前用户只绑定一个账号时，直接进入
                                        vm.jumpLogin(vm.accountList[0]);
                                    }  else {
                                        // 选择登陆账号
                                        vm.userVisible = true;
                                        // 扫码登录
                                        vm.scanVisible = false;
                                        // 用户绑定
                                        vm.userBinding = false;
                                        vm.backChange();
                                    }
                                    clearInterval(timer);
                                    clearInterval(timers);
                                }
                            } else {
                                userError(res.msg);
                            }
                        });
                    }, 3000);
                }
            });
        }
        vm.scanRefresh = function () {
            getCode();
             // 遮罩显示与隐藏
             vm.maskVisible = false;
             vm.maskTestVisible = false;
        };
        vm.jumpLogin = function (item, type) {
            console.log('item', item);
            var userData = {
                username: item.loginName,
                wxId: vm.openid,
                newBind: false,
                sign: vm.sign
            };
            if(window.CryptoJS){
                userData.username= inputsWatcher.encrypt(item.loginName);
            }
            $http.post("/login/loginByWx", userData).then(function(res){
                console.log('res', res.data);
                if(res.success === false) {
                    selectUserError(res.msg);
                } else {
                    if(res.data.success == false) {
                        if (type) {
                            selectUserError(res.data.msg);
                        } else {
                            mgAlert.error(res.data.msg);
                        }
                    } else if(res.data.success){
                        notify.set('登陆成功', { type: 'success', position: 'top' });
                        console.log('data.msg', res.data.msg);
                        location.href = "/ae";
                        // location.href = res.data.msg;
                    } else{
                        mgAlert.error('登录失败');
                    }
                }
            });
        };
        // $scope.$watch('vm.userVisible', function (n, o) {
        //     if (!vm.userVisible && !vm.userBinding) {
        //         $(".light-login").css({
        //             background: 'url("https://asset.mangoerp.com/img/personal/loginbg.jpg") no-repeat top center'
        //           });

        //     }
        //     console.log('n', n);
        // });
        // $scope.$watch('vm.userBinding', function (n, o) {
        //     if (!vm.userVisible && !vm.userBinding) {
        //         $(".light-login").css({
        //             background: 'url("https://asset.mangoerp.com/img/personal/loginbg.jpg") no-repeat top center'
        //           });

        //     }
        // });

        // 登录页广告连接
        $http.get('/admin/advertisting/getloginpagelist', { params: { adLocation: 'LOGIN_PAGE' } }).then(function (response) {
            var res = response.data;
            if (res.success) {
                vm.adList = _.map(res.data.list, function (item, index) {
                    return {
                        id:item.id,
                        title: item.title,
                        url: item.advertisingUrl,
                        imgUrl: item.imageUrl
                    };
                });
            }
        });
        // 用户绑定
        vm.userSubmit = function () {
            console.log('登录');
            if (vm.userInfo.username && vm.userInfo.password) {
                var userData = {
                    username: vm.userInfo.username,
                    password: vm.userInfo.password,
                    newBind: true,
                    wxId: vm.openid
                };
                if(window.CryptoJS){
                    userData.username= inputsWatcher.encrypt(vm.userInfo.username);
                    userData.password= inputsWatcher.encrypt(vm.userInfo.password);
                }
                $http.post("/login/loginByWx", userData).then(function(res){
                    console.log('res', res.data);
                    if(res.data.success == false){
                        userError(res.data.msg);
                    }else if(res.data.success){
                        //location.href = "/ae";
                        location.href = res.data.msg;
                        notify.set('登陆成功', { type: 'success', position: 'top' });
                    }else{
                        userError('登录失败');
                    }
                });
            } else {
                userError('请输入用户名或密码');
            }
        };
        // 选择用户错误提示
        function selectUserError(text) {
            vm.selectHeadTwo = true;
            vm.selectText = text;
        }
        // 用户绑定错误提示
        function userError(text) {
            vm.boxOneError = false;
            vm.boxTwoError = true;
            vm.boxTwoText = text || '';
        }
        // 用户绑定切换
        vm.alreadyOne = function (type) {
            var map = {
                'boxOne': function boxOne () {
                    vm.boxOne = true;
                    vm.boxTwo = false;
                },
                'boxTwo': function boxTwo () {
                    vm.boxOne = false;
                    vm.boxTwo = true;
                }
            };
            map[type]();
        };
        // 选择用户账号
        vm.selectUser = function (index) {
            console.log('index', index);
            vm.selectIndex = index;
        };
        vm.bingingOther = function () {
            console.log('绑定其他账号');
            // 选择登陆账号
            vm.userVisible = false;
            // 扫码登录
            vm.scanVisible = false;
            // 用户绑定
            vm.userBinding = true;
            vm.backChange();
        };
        // 背景切换
        vm.backChange = function () {
            $(".light-login").addClass('bg-none');
            $('#footer-index').hide();
            $('.img-box').hide();
            $('.txt-erp').hide();
        };
        // -----------  注册 -------------
        vm.phoneList = PHONEliST;
        vm.selcted = vm.phoneList[42];

        $("body").removeClass('bg-forgot');
        var host = 'https://' + location.host;
        var defaultData = {
            img: host + '/register/imagecode?t=' + (+new Date())
        };
        vm.data = angular.copy(defaultData);

        // 手机号列表显示隐藏
        vm.phoneflag = false;

        vm.showPhone = function(){
            vm.phoneflag = !vm.phoneflag;
        };

        // 选择各国手机号
        vm.checkPhone = function(item) {
            vm.phoneflag = false;
            vm.selcted = item;
        };

        // 是否同意服务协议
        vm.agreementChecked = false;

        // var hash = location.hash;
        // if (hash.indexOf('=') >= 0) {
        //     vm.linkCode = hash.split('=')[1];
        // }
        var hash = location.hash.split('&');
        _.each(hash, function (ele) {
            if (ele.indexOf('linkCode') >= 0) {
                vm.linkCode = ele.split('=')[1];
            }
            if (ele.indexOf('linkType') >= 0) {
                vm.linkType = ele.split('=')[1];
            }
        });
        //登录页点击量
        vm.closelogin = function( add ){
            $http.put('/admin/advertisting/clickcount', {id: add},
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
            ).then(function (response) {
                var res = response.data;
                if (res.success) {
                }
            });
        };

        //标识是否可发送
        vm.idle = true;
        vm.sendSMS = function () {
            var mobile = vm.data.mobile;
            if (!mobile) {
                return notify.set('请先填写手机号', { type: 'warn', position: 'top' });
            }
            if (vm.signup.mobile.$error.mgValidMobile) {
                return notify.set('手机号已被使用', { type: 'warn', position: 'top' });
            }
            $modal.open({
                templateUrl: 'tpl/get-sms.htm',
                backdrop: 'static',
                // size: 'lg',
                controllerAs: 'vm',
                controller: ['$modalInstance', 'options', function ($modalInstance, options) {
                    var vm = this;
                    var host = 'https://' + location.host;
                    vm.img = host + '/register/phoneimgcode?t=' + (+new Date()) + '&areaCode=' + options.areacode;
                    vm.save = function () {
                        vm.fm.$setSubmitted();
                        if (vm.fm.$valid) {
                            vm.box = { type: 'loading' };
                            console.log(options,"zczc");

                            $http.post('/erp/account/send/smscode', { sendType: 'register', mobile: options.mobile, phoneImgCode: vm.phoneImgCode, areaCode: options.areaCode }).success(function (res) {
                                vm.box = { type: 'close' };
                                if (res.success) {
                                    notify.set('发送成功', { type: 'success', position: 'top' });
                                    $modalInstance.close();
                                } else {
                                    notify.set(res.msg, { type: 'error', position: 'top' });
                                }
                            });
                        }
                    };
                    vm.refresh = function () {
                        vm.img = host + '/register/phoneimgcode?t=' + (+new Date());
                        // vm.fm.imgCode.focus();
                    };
                    vm.cancel = function (msg) {
                        $modalInstance.dismiss(msg);
                    };
                }],
                resolve: {
                    options: function () {
                        return {
                            mobile: mobile,
                            areaCode: vm.selcted.areacode
                        };
                    }
                }
            }).result.then(function () {
                startTimeCount();
            });
        };

        vm.reset = function () {
            _.each(vm.signup.$error.required, function (item) {
                item.$touched = false;
            });
            _.each(vm.signup.$$success.maxlength, function (item) {
                item.$touched = false;
            });
            vm.signup.$submitted = false;
            vm.data = angular.copy(defaultData);
        };

        /**
         * 查看注册服务协议
         * @author 陈威 <490945932@qq.com>
         * @time 2020年05月08日 13:58:42 星期五
         */
        vm.openAgreement = function() {
            $modal.open({
                templateUrl: 'tpl/register-agreement.htm',
                // backdrop: 'static',
                size: 'lg',
                controllerAs: 'vm',
                controller: ['$modalInstance', 'options', function ($modalInstance, options) {
                    var vm = this;
                    vm.cancel = function (msg) {
                        $modalInstance.dismiss(msg);
                    };
                }],
                resolve: {
                    options: function () {
                        return {

                        };
                    }
                }
            }).result.then(function (data) {
                console.log('data', data);
            });
        };

        vm.submitRegister = function (noRedirect) {

            vm.signup.$setSubmitted();
            if (vm.signup.$valid) {
                if (!vm.agreementChecked) {
                    notify.set('请阅读并同意协议', { type: 'warn', position: 'top' });
                    return;
                }
                var data = {
                    loginName: vm.data.name,
                    plainPassword: vm.data.password,
                    confirmPassword: vm.data.repeatPassword,
                    mobile: vm.data.mobile,
                    mobileCode: vm.data.mobileCode,
                    imgCode: vm.data.code,
                    areacode: vm.selcted.areacode,
                    wxId: vm.openid
                };
                console.log(data);
                if(window.CryptoJS){
                    data.loginName= inputsWatcher.encrypt(vm.data.name);
                    data.plainPassword= inputsWatcher.encrypt(vm.data.password);
                    data.confirmPassword= inputsWatcher.encrypt(vm.data.repeatPassword);
                }
                if (vm.linkCode) {
                    data['linkCode'] = vm.linkCode;
                }
                if (vm.linkType) {
                    data['linkType'] = vm.linkType;
                }

                $http.post('/register/ajax', data).success(function (res) {
                    if (res.success) {
                        notify.set('注册成功', { type: 'success', position: 'top' });
                        if (noRedirect) {
                            vm.reset();
                        } else {
                            location.href = '//mangoerp.com/erp/#/erp/dashboard3';
                        }
                    } else {
                        notify.set(res.msg, { type: 'error', position: 'top' });
                    }
                });
            }
        };
        vm.refresh = function () {
            vm.data.img = host + '/register/imagecode?t=' + (+new Date());
            // vm.signup.imgCode.focus();
        };

        //g开始60秒倒计时
        function startTimeCount() {
            vm.idle = false;
            vm.second = 61;
            timeCount();

            function timeCount() {
                vm.second--;
                if (vm.second > 0) {
                    $timeout(timeCount, 1000);
                } else {
                    vm.idle = true;
                }
            }
        }

        // 系统首页广告登录后重新显示
        storage.set('erp.dashboard3',{});
        // 系统首页侧边栏广告登录后重新显示
        localStorage['slide-menu'] = JSON.stringify([ { state: true }, { state: true }, { state: true }, { state: true }, { state: true }, { state: true } ]);
    }]);

