angular.module('web.account').run(['$templateCache', function($templateCache) {
  $templateCache.put("tpl/auth-error.htm",
    "<div class=\"panel-login\" style=\"margin-bottom:0;\">\n" +
    "    <div class=\"panel-heading1\"><strong>selleros授权失败</strong></div>\n" +
    "    <div class=\"panel-content1\" style=\"padding-bottom:90px;\">\n" +
    "        <div>\n" +
    "            <i class=\"mg-icon mg-icon-shibai text-danger\" style=\"font-size:70px;\"></i>\n" +
    "        </div>\n" +
    "        <p class=\"bigger-120\">授权失败</p>\n" +
    "        <p class=\"grey\">{{vm.msg}}</p>\n" +
    "    </div>\n" +
    "</div>");
  $templateCache.put("tpl/auth-login.htm",
    "<div class=\"panel-login\" style=\"margin-bottom:0;\">\n" +
    "    <div class=\"panel-heading1\"><strong>selleros授权</strong></div>\n" +
    "    <div class=\"panel-body1\">\n" +
    "        <form name=\"signin\" novalidate ng-submit=\"submitLogin()\" method=\"post\">\n" +
    "            <fieldset>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(signin.username.$touched || signin.$submitted) && signin.username.$invalid}\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input name=\"username\" id=\"username\" type=\"text\" class=\"form-control\" placeholder=\"芒果账号\"\n" +
    "                            ng-model=\"username\" required=\"required\" />\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-yonghuming\"></i>\n" +
    "                    </span>\n" +
    "                    <div ng-messages=\"signin.username.$error\" ng-if=\"signin.username.$touched || signin.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入芒果账号</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(signin.password.$touched || signin.$submitted) && signin.password.$invalid}\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input name=\"password\" id=\"password\" type=\"password\" class=\"form-control\" placeholder=\"密码\"\n" +
    "                            ng-model=\"password\" required=\"required\" />\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                    </span>\n" +
    "                    <div ng-messages=\"signin.password.$error\" ng-if=\"signin.password.$touched || signin.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入密码</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <button type=\"submit\" class=\"btn btn-sm btn-primary btn-block\">\n" +
    "                        <span class=\"bigger-110\">授权并登录</span>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <!-- 还没有芒果账号？ -->\n" +
    "                    <a href=\"/login#/forgot\" target=\"_blank\" data-target=\"#forgot-box\" class=\"grey\">\n" +
    "                        忘记密码\n" +
    "                    </a>\n" +
    "                    <span class=\"pull-right\">\n" +
    "                        <a href=\"#/authregister/{{vm.data.uid}}/{{vm.data.sign}}\" data-target=\"#signup-box\">\n" +
    "                            手动注册\n" +
    "                        </a>\n" +
    "                        <a\n" +
    "                            href=\"/register/registerBySelleros?uid={{vm.data.uid}}&sign={{vm.data.sign}}\">\n" +
    "                            自动注册\n" +
    "                            <i class=\"fa fa-question-circle\" tooltip=\"根据selleros授权自动注册芒果账号，默认密码为手机号后6位\"></i>\n" +
    "                        </a>\n" +
    "                    </span>\n" +
    "                </div>\n" +
    "            </fieldset>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>");
  $templateCache.put("tpl/auth-register.htm",
    "<div class=\"panel-login\" style=\"margin-bottom:0;\">\n" +
    "    <div class=\"panel-heading1\"><strong>selleros授权</strong></div>\n" +
    "    <div class=\"panel-body1\">\n" +
    "        <form name=\"vm.signup\" ng-submit=\"vm.submitRegister()\" method=\"post\" novalidate autocomplete=\"off\">\n" +
    "            <fieldset>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(vm.signup.loginName.$touched || vm.signup.$submitted) && vm.signup.loginName.$invalid}\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input type=\"text\" name=\"loginName\" ng-model=\"vm.data.name\" class=\"form-control\"\n" +
    "                            placeholder=\"用户名\" required=\"required\" maxlength=\"32\" minlength=\"5\" mg-as-code\n" +
    "                            mg-valid-uname />\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-yonghuming\"></i>\n" +
    "                    </span>\n" +
    "                    <div ng-messages=\"vm.signup.loginName.$error\"\n" +
    "                        ng-if=\"vm.signup.loginName.$touched || vm.signup.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入用户名</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"minlength\">用户名不能小于5位</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"mgAsCode\">用户名只能为字母、数字、下划线组成</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"mgValidUname\">用户名已存在</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(vm.signup.plainPassword.$touched || vm.signup.$submitted) && vm.signup.plainPassword.$invalid}\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input type=\"password\" name=\"plainPassword\" ng-model=\"vm.data.password\" class=\"form-control\"\n" +
    "                            placeholder=\"密码\" required=\"required\" minlength=\"6\" maxlength=\"20\" />\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                    </span>\n" +
    "                    <div ng-messages=\"vm.signup.plainPassword.$error\"\n" +
    "                        ng-if=\"vm.signup.plainPassword.$touched || vm.signup.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入密码</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"minlength\">密码长度不能小于6位</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(vm.signup.confirmPassword.$touched || vm.signup.$submitted) && vm.signup.confirmPassword.$invalid}\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input type=\"password\" name=\"confirmPassword\" ng-model=\"vm.data.repeatPassword\"\n" +
    "                            class=\"form-control\" placeholder=\"确认密码\" required=\"required\" maxlength=\"20\"\n" +
    "                            repeatpwd=\"{{vm.data.password}}\" />\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                    </span>\n" +
    "                    <div ng-messages=\"vm.signup.confirmPassword.$error\"\n" +
    "                        ng-if=\"vm.signup.confirmPassword.$touched || vm.signup.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入确认密码</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"repeatpwd\">两次密码不一致</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(vm.signup.mobile.$touched || vm.signup.$submitted) && vm.signup.mobile.$invalid}\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input type=\"text\" name=\"mobile\" ng-model=\"vm.data.mobile\" class=\"form-control\"\n" +
    "                            placeholder=\"手机号\" maxlength=\"11\" pattern=\"^1\\d{10}$\" required=\"required\" mg-valid-mobile />\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-shouji\"></i>\n" +
    "                    </span>\n" +
    "                    <div ng-messages=\"vm.signup.mobile.$error\"\n" +
    "                        ng-if=\"vm.signup.mobile.$touched || vm.signup.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请填写手机号</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"pattern\">手机格式不正确</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"mgValidMobile\">手机号已被使用</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(vm.signup.mobileCode.$touched || vm.signup.$submitted) && vm.signup.mobileCode.$invalid}\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <span class=\"col-xs-6\">\n" +
    "                            <input type=\"text\" name=\"mobileCode\" ng-model=\"vm.data.mobileCode\"\n" +
    "                                class=\"form-control imgcode\" placeholder=\"手机验证码\" required=\"required\" maxlength=\"6\"\n" +
    "                                required=\"required\" mg-valid-mobile-code />\n" +
    "                        </span>\n" +
    "                        <span class=\"col-xs-6\">\n" +
    "                            <button ng-show=\"vm.idle\" type=\"button\" class=\"btn btn-default btn-block\"\n" +
    "                                ng-click=\"vm.sendSMS()\">发送验证码</button>\n" +
    "                            <button ng-hide=\"vm.idle\" type=\"button\" class=\"btn btn-default btn-block\" disabled>重新发送\n" +
    "                                {{vm.second}}秒</button>\n" +
    "                        </span>\n" +
    "                    </div>\n" +
    "                    <div ng-messages=\"vm.signup.mobileCode.$error\"\n" +
    "                        ng-if=\"vm.signup.mobileCode.$touched || vm.signup.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入手机验证码</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"mgValidMobileCode\">\n" +
    "                            {{vm.signup.mobileCode._errorMsg}}\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"space-6\"></div>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(vm.signup.imgCode.$touched || vm.signup.$submitted) && vm.signup.imgCode.$invalid}\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <span class=\"col-xs-6\">\n" +
    "                            <input type=\"text\" name=\"imgCode\" ng-model=\"vm.data.code\" class=\"form-control imgcode\"\n" +
    "                                placeholder=\"验证码\" required=\"required\" maxlength=\"4\" mg-valid-code />\n" +
    "                        </span>\n" +
    "                        <span class=\"col-xs-6\">\n" +
    "                            <img class=\"imgcode\" ng-src=\"{{vm.data.img}}\" ng-click=\"vm.refresh()\" title=\"点击刷新验证码\" />\n" +
    "                        </span>\n" +
    "                    </div>\n" +
    "                    <div ng-messages=\"vm.signup.imgCode.$error\"\n" +
    "                        ng-if=\"vm.signup.imgCode.$touched || vm.signup.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入验证码</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"mgValidCode\">验证码错误</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"space-6\"></div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <button type=\"submit\" class=\"btn btn-sm btn-primary btn-block\">\n" +
    "                        <span class=\"bigger-110\">授权并注册</span>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    已拥有账号？\n" +
    "                    <a href=\"#/authlogin/{{vm.data.uid}}/{{vm.data.sign}}\" data-target=\"#login-box\">\n" +
    "                        马上登录\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </fieldset>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>");
  $templateCache.put("tpl/forgot-expire.htm",
    "<div class=\"panel-login\" style=\"margin-bottom:0;\">\n" +
    "    <div class=\"panel-heading1\"><strong>错误</strong></div>\n" +
    "    <div class=\"panel-content1\">\n" +
    "        <div>\n" +
    "            <i class=\"mg-icon mg-icon-shibai text-danger\" style=\"font-size:70px;\"></i>\n" +
    "        </div>\n" +
    "        <p class=\"bigger-120\">请重新找回密码</p>\n" +
    "        <p class=\"grey\">对不起，您手机验证码已过期或不正确。</p>\n" +
    "    </div>\n" +
    "    <div class=\"panel-body1\">\n" +
    "        <a href=\"#/forgot\" data-target=\"#login-box\" class=\"btn btn-sm btn-primary btn-block\">\n" +
    "            重新发送验证码\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>");
  $templateCache.put("tpl/forgot-newpwd.htm",
    "<div class=\"panel-login\" style=\"margin-bottom:0;\">\n" +
    "    <div class=\"panel-heading1\"><strong>找回密码</strong></div>\n" +
    "    <div class=\"panel-body1\">\n" +
    "        <form name=\"vm.signup\" ng-submit=\"vm.createNewPwd()\" method=\"post\" novalidate autocomplete=\"off\">\n" +
    "            <fieldset>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <p class=\"form-control-static grey\">用户名：{{vm.data.loginName}}</p>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(vm.signup.plainPassword.$touched || vm.signup.$submitted) && vm.signup.plainPassword.$invalid}\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input type=\"password\" name=\"plainPassword\" ng-model=\"vm.data.password\" class=\"form-control\"\n" +
    "                            placeholder=\"新密码\" required=\"required\" minlength=\"6\" maxlength=\"20\" mg-as-pwd tooltip-placement=\"right\" tooltip-trigger=\"focus\" tooltip=\"特殊符号为以下几种._~!@#$^&*\" />\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                    </span>\n" +
    "                    <div ng-messages=\"vm.signup.plainPassword.$error\"\n" +
    "                        ng-if=\"vm.signup.plainPassword.$touched || vm.signup.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入密码</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"mgAsPwd\">密码长度8-20，至少包含一个英文字母、一个数字和一个特殊字符</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"minlength\">密码长度不能小于6位</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(vm.signup.confirmPassword.$touched || vm.signup.$submitted) && vm.signup.confirmPassword.$invalid}\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input type=\"password\" name=\"confirmPassword\" ng-model=\"vm.data.repeatPassword\"\n" +
    "                            class=\"form-control\" placeholder=\"确认新密码\" required=\"required\" maxlength=\"20\"\n" +
    "                            repeatpwd=\"{{vm.data.password}}\" />\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                    </span>\n" +
    "                    <div ng-messages=\"vm.signup.confirmPassword.$error\"\n" +
    "                        ng-if=\"vm.signup.confirmPassword.$touched || vm.signup.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入确认密码</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"repeatpwd\">两次密码不一致</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"space-6\"></div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <button type=\"submit\" class=\"btn btn-primary btn-block\">\n" +
    "                        <span class=\"bigger-110\">修改密码</span>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </fieldset>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>");
  $templateCache.put("tpl/forgot-next.htm",
    "<div id=\"login-box\" class=\"signup-box visible widget-box no-border\">\n" +
    "    <div class=\"widget-body\">\n" +
    "        <div class=\"widget-main\">\n" +
    "            <h4 class=\"header blue lighter bigger\">\n" +
    "                <i class=\"ace-icon fa fa-coffee green\"></i>\n" +
    "                找回密码\n" +
    "            </h4>\n" +
    "            <div class=\"space-6\"></div>\n" +
    "            <div class=\"bg-success bigger-120\" style=\"padding:12px;\">\n" +
    "                <p>重置密码邮件已发出，请登录您的邮箱 <strong class=\"text-danger\">{{email}}</strong> 查收，该邮件24小时内有效。</p>\n" +
    "                <p>若没有收到，请查看是否在邮件垃圾箱中。</p>\n" +
    "            </div>\n" +
    "        </div><!-- /.widget-main -->\n" +
    "        <div class=\"toolbar center\">\n" +
    "            <a href=\"#/forgot\" data-target=\"#login-box\" class=\"back-to-login-link block\">\n" +
    "                <i class=\"ace-icon fa fa-arrow-left\"></i>\n" +
    "                返回，重新发送\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div><!-- /.widget-body -->\n" +
    "</div><!-- /.login-box -->\n" +
    "");
  $templateCache.put("tpl/forgot-success.htm",
    "<div class=\"panel-login\" style=\"margin-bottom:0;\">\n" +
    "    <div class=\"panel-heading1\"><strong>找回密码</strong></div>\n" +
    "    <div class=\"panel-content1\">\n" +
    "        <div>\n" +
    "            <i class=\"mg-icon mg-icon-dengluchenggong\"\n" +
    "                style=\"color:#04be03;font-size:70px;\"></i>\n" +
    "        </div>\n" +
    "        <p class=\"bigger-120\">密码重置成功</p>\n" +
    "        <p class=\"grey\">请使用新密码重新登录</p>\n" +
    "    </div>\n" +
    "    <div class=\"panel-body1\">\n" +
    "        <a href=\"#/signin\" data-target=\"#login-box\" class=\"btn btn-sm btn-primary btn-block\">\n" +
    "            立即登录\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>");
  $templateCache.put("tpl/forgot.htm",
    "<div class=\"panel-login\" style=\"margin-bottom:0;\">\n" +
    "    <div class=\"panel-heading1\"><strong>找回密码</strong></div>\n" +
    "    <div class=\"panel-body1\">\n" +
    "        <form name=\"vm.forgot\" ng-submit=\"vm.submitForgot('/register/retrievepassword')\" method=\"post\" novalidate>\n" +
    "            <fieldset>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(vm.forgot.mobile.$touched || vm.forgot.$submitted) && vm.forgot.mobile.$invalid}\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input type=\"text\" name=\"mobile\" ng-model=\"vm.data.mobile\" class=\"form-control\"\n" +
    "                            placeholder=\"手机号\" maxlength=\"11\" required=\"required\" style=\"padding-left: 60px;\" />\n" +
    "                            <div class=\"flag-container\">\n" +
    "                                <div class=\"selected-flag\" ng-click=\"vm.showPhone()\">\n" +
    "                                    <div class=\"iti-flag\" ng-class=\"vm.selcted.className\"></div>\n" +
    "                                    <div class=\"iti-arrow\"></div>\n" +
    "                                </div>\n" +
    "                                <ul class=\"country-list\" ng-show=\"vm.phoneflag\">\n" +
    "                                    <li id=\"a\" ng-mouseover=\"vm.phoneover()\" ng-click=\"vm.checkPhone(item)\" ng-repeat=\"item in vm.phoneList\" class=\"country preferred active\" data-dial-code=\"86\" data-country-code=\"cn\">\n" +
    "                                        <div class=\"flag-box\">\n" +
    "                                            <div class=\"iti-flag\" ng-class=\"item.className\"></div>\n" +
    "                                        </div>\n" +
    "                                        <span class=\"country-name\">{{item.english_name}}({{item.chinese_name}})</span>\n" +
    "                                        <span class=\"dial-code\">{{item.areacode}}</span>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </div>\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-shouji\"></i>\n" +
    "                    </span>\n" +
    "                    <div ng-messages=\"vm.forgot.mobile.$error\"\n" +
    "                        ng-if=\"vm.forgot.mobile.$touched || vm.forgot.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请填写手机号</div>\n" +
    "\n" +
    "                        <div class=\"help-block\" ng-message=\"pattern\">手机格式不正确</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\"\n" +
    "                    ng-class=\"{'has-error':(vm.forgot.mobileCode.$touched || vm.forgot.$submitted) && vm.forgot.mobileCode.$invalid}\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <span class=\"col-xs-6\">\n" +
    "                            <input type=\"text\" name=\"mobileCode\" ng-model=\"vm.data.mobileCode\"\n" +
    "                                class=\"form-control imgcode\" placeholder=\"手机验证码\" required=\"required\" maxlength=\"6\"\n" +
    "                                required=\"required\" />\n" +
    "                        </span>\n" +
    "                        <span class=\"col-xs-6\">\n" +
    "                            <button ng-show=\"vm.idle\" type=\"button\" class=\"btn btn-default btn-block\"\n" +
    "                                ng-click=\"vm.sendSMS()\">发送验证码</button>\n" +
    "                            <button ng-hide=\"vm.idle\" type=\"button\" class=\"btn btn-default btn-block\" disabled>重新发送\n" +
    "                                {{vm.second}}秒</button>\n" +
    "                        </span>\n" +
    "                    </div>\n" +
    "                    <div ng-messages=\"vm.forgot.mobileCode.$error\"\n" +
    "                        ng-if=\"vm.forgot.mobileCode.$touched || vm.forgot.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入手机验证码</div>\n" +
    "                        <!-- <div class=\"help-block\" ng-message=\"mgValidMobileCode\">{{vm.forgot.mobileCode._errorMsg}}\n" +
    "                        </div> -->\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <button type=\"submit\" class=\"btn btn-sm btn-primary btn-block\">\n" +
    "                        <span class=\"bigger-110\">下一步</span>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <a href=\"//www.mangoerp.com/erp#/erp/dashboard3\" data-target=\"#login-box\">\n" +
    "                        返回登录\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </fieldset>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
  $templateCache.put("tpl/get-sms.htm",
    "<mg-box ng-model=\"vm.box\">\n" +
    "    <div class=\"modal-header\">\n" +
    "        <button type=\"button\" class=\"close\" ng-click=\"vm.cancel()\">&times;</button>\n" +
    "        <h3 class=\"modal-title\">请输入验证码</h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body modal-order\">\n" +
    "        <form name=\"vm.fm\" class=\"form-horizontal\" ng-submit=\"vm.save()\" novalidate autocomplete=\"off\">\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-error':(vm.fm.imgCode.$touched || vm.fm.$submitted) && vm.fm.imgCode.$invalid}\">\n" +
    "                <label class=\"col-sm-2 control-label\">验证码</label>\n" +
    "                <div class=\"col-xs-6\">\n" +
    "                    <input type=\"text\" name=\"imgCode\" ng-model=\"vm.phoneImgCode\" class=\"form-control imgcode\"\n" +
    "                        placeholder=\"验证码\" required=\"required\" maxlength=\"4\" mg-valid-phone-code />\n" +
    "                    <div ng-messages=\"vm.fm.imgCode.$error\" ng-if=\"vm.fm.imgCode.$touched || vm.fm.$submitted\">\n" +
    "                        <div class=\"help-block\" ng-message=\"required\">请输入验证码</div>\n" +
    "                        <div class=\"help-block\" ng-message=\"mgValidPhoneCode\">验证码错误</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"col-xs-4\">\n" +
    "                    <img ng-src=\"{{vm.img}}\" ng-click=\"vm.refresh()\" title=\"点击刷新验证码\" />\n" +
    "                </div>\n" +
    "\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "        <button type=\"button\" class=\"btn btn-primary\" ng-click=\"vm.save()\">确认</button>\n" +
    "    </div>\n" +
    "</mg-box>");
  $templateCache.put("tpl/login.htm",
    "<div class=\"row\">\n" +
    "    <div class=\"col-xs-6\">\n" +
    "        <div class=\"txt-erp\">\n" +
    "            芒果店长<br>\n" +
    "            让跨境生意更简单\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-6\">\n" +
    "        <div class=\"panel-login panel-login-right\">\n" +
    "            <!-- <div class=\"panel-heading1\"><strong>用户登录</strong></div> -->\n" +
    "            <div class=\"panel-heading1\">\n" +
    "                <img src=\"//img2.mangoerp.com/userbucket/adminadmin/2023-04-03/1680514572881.png\" alt=\"\" class=\"container__changeUser--image\"><strong>用户登录</strong>\n" +
    "                <div class=\"container__changeUser\">\n" +
    "                    <a href=\"/login#/scan\">\n" +
    "                        <img src=\"//img2.mangoerp.com/userbucket/adminadmin/2022-10-24/1666579217510.png\" alt=\"\">\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"panel-body1\">\n" +
    "                <form name=\"signin\" novalidate ng-submit=\"submitLogin()\" method=\"post\">\n" +
    "                    <fieldset>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(signin.username.$touched || signin.$submitted) && signin.username.$invalid}\">\n" +
    "                            <span class=\"block input-icon\">\n" +
    "                                <input name=\"username\" id=\"username\" type=\"text\" class=\"form-control\" placeholder=\"用户名\"\n" +
    "                                    ng-model=\"username\" required=\"required\" />\n" +
    "                                <i class=\"ace-icon mg-icon mg-icon-yonghuming\"></i>\n" +
    "                            </span>\n" +
    "                            <div ng-messages=\"signin.username.$error\"\n" +
    "                                ng-if=\"signin.username.$touched || signin.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入用户名</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(signin.password.$touched || signin.$submitted) && signin.password.$invalid}\">\n" +
    "                            <span class=\"block input-icon\">\n" +
    "                                <input name=\"password\" id=\"password\" type=\"password\" class=\"form-control\"\n" +
    "                                    placeholder=\"密码\" ng-model=\"password\" required=\"required\" />\n" +
    "                                <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                            </span>\n" +
    "                            <div ng-messages=\"signin.password.$error\"\n" +
    "                                ng-if=\"signin.password.$touched || signin.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入密码</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <label class=\"inline\">\n" +
    "                                <input name=\"rememberMe\" type=\"checkbox\" class=\"ace\" ng-model=\"rememberMe\" />\n" +
    "                                <span class=\"lbl\">&nbsp;&nbsp;记住我</span>\n" +
    "                            </label>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <button type=\"submit\" class=\"btn btn-sm btn-primary btn-block\">\n" +
    "                                <span class=\"bigger-110\">登录</span>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <a href=\"#forgot\" data-target=\"#forgot-box\" class=\"grey\">\n" +
    "                                忘记密码\n" +
    "                            </a>\n" +
    "                            <a href=\"#/signup\" data-target=\"#signup-box\" class=\"pull-right\">\n" +
    "                                新用户注册\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </fieldset>\n" +
    "                </form>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"row img-box\">\n" +
    "    <div class=\"col-sm-3 hidden-xs\" ng-repeat=\"item in vm.adList\">\n" +
    "        <a href=\"{{item.url}}\" target=\"_blank\" ng-click=\"vm.closelogin(item.id)\"><img src=\"{{item.imgUrl}}\" alt=\"shopee\" /></a>\n" +
    "    </div>\n" +
    "    <!-- <div class=\"col-sm-3 hidden-xs\">\n" +
    "        <a href=\"https://shopee.cn/s/gECPiRq\" target=\"_blank\"><img src=\"//mangoerpbucket.oss-cn-hangzhou.aliyuncs.com/newsbanner/2021/20210817-shopee.png\" alt=\"shopee\" /></a>\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-3 hidden-xs\">\n" +
    "        <a href=\"/bridge/clientStatistics?code=AD0005\" target=\"_blank\"><img\n" +
    "            src=\"//sz2.mangoerp.com/ckeditor/2019-07-11/1562807442485.png\" alt=\"kilimall\" /></a>\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-3 hidden-xs\">\n" +
    "        <a href=\"/bridge/clientStatistics?code=AD0001\" target=\"_blank\"><img\n" +
    "                src=\"//sz2.mangoerp.com/ckeditor/2019-05-16/1558000248579.png\" alt=\"pingpong\" /></a>\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-3 hidden-xs\">\n" +
    "        <a href=\"https://sale.1688.com/sale/dacu/commonnew/1223nhjyjdfbyzhcJ5.html?__pageId__=231359&cms_id=231359&wh_pha=true&__existtitle__=1&enableWK=YES&tracelog=1223mango&fromkv=1223mango\" target=\"_blank\"><img\n" +
    "                src=\"http://img2.mangoerp.com/userbucket/adminadmin/2021-12-21/1640067274615.png\" alt=\"1688\" /></a>\n" +
    "    </div> -->\n" +
    "</div>\n" +
    "");
  $templateCache.put("tpl/register-agreement.htm",
    "<mg-box ng-model=\"vm.box\">\n" +
    "    <div class=\"modal-header\">\n" +
    "        <button type=\"button\" class=\"close\" ng-click=\"vm.cancel()\">&times;</button>\n" +
    "        <h3 class=\"modal-title\">芒果店长ERP服务协议</h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body modal-agreement\">\n" +
    "        <p>欢迎注册芒果店长ERP</p>\n" +
    "        <p>我已阅读并同意《芒果店长ERP服务协议》</p>\n" +
    "        <p>《芒果店长ERP服务协议》</p>\n" +
    "        <p>感谢您选择芒果店长ERP服务！</p>\n" +
    "        <p>芒果店长ERP软件（以下简称“本软件”）由杭州码耘网络技术有限公司开发并运营维护，杭州码耘网络技术有限公司（以下简称“本公司”）是芒果店长的商标专用权人。您（以下简称“用户”）可通过注册并登录芒果店长ERP、芒果店长ERP官方网站（www.mangoerp.com），或登录本公司后续可能开通的其他网站及其他移动客户端应用程序使用本软件并享受本公司提供的服务。\n" +
    "            本服务协议（以下简称“本协议”）是用户和本公司之间就用户使用本软件和本公司为用户提供服务所达成的合法协议，用户通过网络页面点击确认或以其他方式选择接受本协议，即表示用户与本公司已达成协议并同意接受本协议的全部约定内容。在接受本协议条款之前，请仔细阅读本协议条款的全部内容，如对条款内容有疑问，可询问本公司相关人员，本公司将向用户进行充分解释。如用户不同意本协议的任何内容，或者无法准确理解条款的含义，请不要进行后续操作。</p>\n" +
    "        <p>一、本公司承诺与保证</p>\n" +
    "        <p>本公司保证有合法权利许可用户使用本软件。</p>\n" +
    "        <p>本公司承诺许可用户使用本软件之行为未对任何第三方合法权益构成侵害。</p>\n" +
    "        <p>本公司负责及时对本软件进行升级、维护和管理，并通过客服电话、在线客服等方式向用户提供咨询及技术支持服务。</p>\n" +
    "        <p>本公司承诺向用户提供的软件中不含有：</p>\n" +
    "        <p>任何已知的蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序，但在使用中发生软件被病毒侵入或黑客攻击的除外；</p>\n" +
    "        <p>任何已知的漏洞、恶意软件。</p>\n" +
    "        <p>二、费用及续费</p>\n" +
    "        <p>除初始试用情形和经本公司授权免费使用的情况外，用户使用本软件须按照本公司的价格体系向本公司支付相应的软件使用费。用户未按照约定支付全部费用的，本公司有权暂停或终止用户使用本软件。</p>\n" +
    "        <p>在用户购买使用期内，用户自行停止使用本软件或因用户严重违反本协议项下条款而被暂停或终止使用本软件的，用户已支付的费用将不作退还，本公司将继续维护用户的账户和数据信息至使用期届满。</p>\n" +
    "        <p>在使用期届满前，用户自行停止使用的可随时申请恢复使用本软件，但停止使用期间的费用不予退还且使用期限不做顺延；因用户严重违反本协议项下条款而被暂停或终止使用本软件的，用户可在承担相应违约责任后向本公司申请恢复使用本软件。</p>\n" +
    "        <p>使用期届届满，用户选择续费的，至少应在使用期届满前7个工作日内支付续费款项，以使服务得以继续进行；如续费时本软件的名称、功能或使用价格等作出调整，双方同意按照届时有效的新的服务体系或价格履行，但调整前用户已经支付的款项不受影响。</p>\n" +
    "        <p>三、用户数据</p>\n" +
    "        <p>本公司有义务保护用户数据安全，但非因本公司过错导致的，或因不可抗力事件导致的服务器损坏、故障、灭失、用户数据受损、丢失的，本公司不承担责任，但本公司会竭力帮助用户恢复或找回数据；不可抗力事件指本公司无法预见、无法避免、无法克服的情形，如黑客攻击、病毒感染、政府禁令、系统性电力故障、火灾、水灾、地震及其他自然灾害等。</p>\n" +
    "        <p>因用户保管不当泄露账号密码或操作不当导致用户数据丢失、错误、损坏的，相应损失及责任由用户自行承担，与本公司无关。</p>\n" +
    "        <p>因用户主动向第三方公开数据，或本公司经用户授权向第三方提供数据或开放接口而造成用户自身、第三方、本公司损失的，用户自行承担责任。</p>\n" +
    "        <p>用户和本公司均有配合有权国家机关开展工作的义务，在因司法机关、行政机关和其他经法律、法规授权的有权机关（包括但不限于公安、国安、检察、监察委、法院、市场监督管理、税务等部门）出于司法行为需要或行政管理需要向本公司查询、调取用户数据，本公司将全面、彻底地配合，并有权自行决定是否通知用户以及通知的时间，若该过程中用户产生任何损失，本公司不承担责任。</p>\n" +
    "        <p>在付费使用期内，用户可通过登录芒果店长ERP账户查看、备份保存电商平台允许用户进行上述操作的数据信息。用户使用期满且不再续费的，本公司有权注销或关闭用户的账号。</p>\n" +
    "        <p>用户违反本软件操作规范使用或因自身对本软件提供数据产生误解、误操作、误决策而造成对用户自身、第三方、本公司损失的，由用户依法承担责任。</p>\n" +
    "        <p>用户理解并认可：鉴于软件行业的特殊性，以及本公司为了给用户更好的产品和服务体验，需要对本软件进行迭代更新；本公司将提前告知用户迭代更新的时间并提醒用户做好相应的准备，包括但不限于数据备份等。本公司尽量将迭代更新的时间与用户业务高峰时间错开，并将可能产生的影响降到最低。</p>\n" +
    "        <p>四、保密条款</p>\n" +
    "        <p>保密资料指由一方向另一方披露的所有技术及非技术信息(包括但不限于产品资料，产品计划，价格，财务及营销规划，业务战略，客户信息，客户数据，研发，软件硬件，API应用数据接口，技术说明，设计，特殊公式，特殊算法等)。</p>\n" +
    "        <p>双方同意对获悉的对方保密资料予以保密，并严格限制接触上述保密信息的员工遵守本条之保密义务。除非国家机关依法强制要求或上述保密资料已经进入公有领域外，接受保密资料的一方不得对外披露。</p>\n" +
    "        <p>保密义务不因本服务条款的终止而失效。</p>\n" +
    "        <p>本公司尊重用户隐私，承诺不透露用户注册资料信息及本软件中的用户数据，但以下几种本公司在诚信的基础上认为必须披露或使用的情形除外：</p>\n" +
    "        <p>本公司可能通过使用用户数据，向用户提供服务，包括但不限于向用户发出产品，订单和服务信息；</p>\n" +
    "        <p>为提供用户所要求的软件或服务，经用户授权而必须和第三方分享用户数据；</p>\n" +
    "        <p>为维护本公司知识产权和其他重要权利。</p>\n" +
    "        <p>五、用户管理</p>\n" +
    "        <p>用户自行承担所发布内容的相关法律责任，并作出以下承诺：</p>\n" +
    "        <p>在本软件上发布信息或者使用本软件时必须符合中国有关法规， 不利用本公司提供的资源和服务上传（Upload）、下载（download）、储存、发布如下信息或者内容，不为他人发布该等信息提供任何便利：</p>\n" +
    "        <p>违反国家规定的政治宣传和新闻信息；</p>\n" +
    "        <p>涉及国家秘密和安全的信息；</p>\n" +
    "        <p>封建迷信和淫秽、色情、下流的信息或教唆犯罪的信息；</p>\n" +
    "        <p>博彩有奖、赌博游戏、“私服”、“外挂”等非法互联网出版活动；</p>\n" +
    "        <p>违反国家民族和宗教政策的信息；</p>\n" +
    "        <p>妨碍互联网运行安全的信息；</p>\n" +
    "        <p>侵害他人合法权益的信息和其他有损于社会秩序、社会治安、公共道德的信息或内容；</p>\n" +
    "        <p>其他违反法律法规、部门规章或国家政策的内容。</p>\n" +
    "        <p>在本软件上发布信息或者使用本软件或服务时合法真实且不侵害任何第三方的合法权益。</p>\n" +
    "        <p>不进行任何破坏或试图破坏网络安全的行为（包括但不限于钓鱼，黑客，网络诈骗，网站或空间中含有或涉嫌散播：病毒、木马、恶意代码，及通过虚拟服务器对其他网站、服务器进行涉嫌攻击行为如扫描、ARP欺骗、DOS等）；</p>\n" +
    "        <p>不以任何方式干扰本软件的功能和本公司的服务。</p>\n" +
    "        <p>六、违约责任</p>\n" +
    "        <p>用户明确同意使用本软件的风险由用户自行承担。用户须对自己在使用本软件或本公司服务过程中的行为承担法律责任，使用本软件进行商务活动所引起的一切法律纠纷均与本公司无关。本服务条款任何一方违约均须依法承担违约责任。用户理解并同意：</p>\n" +
    "        <p>因现有技术限制，本软件及本公司提供的服务可能存在瑕疵，并不能保证在任何情况下都能正常执行或达到用户所期望的结果。</p>\n" +
    "        <p>用户或使用用户帐号的其他人侵犯第三方知识产权及其他权利而导致被侵权人索赔的，由用户自行承担责任。</p>\n" +
    "        <p>鉴于计算机、互联网的特殊性，下述情况不属于本公司违约：</p>\n" +
    "        <p>本公司在进行芒果店长ERP服务配置、维护、升级时，需短时间中断服务；</p>\n" +
    "        <p>本公司提供的服务涉及到互联网及相关信息等服务，可能会受到Internet不稳定因素的影响。因此服务存在因上述不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机、通信网络、Internet等原因等造成用户访问芒果店长ERP服务速度下降或无法使用；</p>\n" +
    "        <p>由于用户故意或过失导致遭受损失的，本公司不承担任何责任；</p>\n" +
    "        <p>因本公司过错或有证据证明因本软件原因导致用户数据丢失等给用户造成的损失，用户同意本公司的最高赔偿额不高于用户首次提出赔偿主张时最近一次续费实际支付的使用费。客户未续费的，以首次支付的“使用费”为限额；</p>\n" +
    "        <p>在任何情况下，本公司对本协议条款所承担的违约赔偿责任总额不超过违约服务对应之服务费总额。</p>\n" +
    "        <p>七、知识产权保护</p>\n" +
    "        <p>本软件的著作权、专利权及其他知识产权均归本公司所有，未经本公司书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。</p>\n" +
    "        <p>除非法律允许或本公司书面许可，用户不得从事下列行为：</p>\n" +
    "        <p>删除本软件及其副本上关于著作权的信息；</p>\n" +
    "        <p>对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；</p>\n" +
    "        <p>对本公司拥有知识产权的内容进行使用、出租、出借、复制、修改、转载、汇编、发表、出版、建立镜像站点等；</p>\n" +
    "        <p>对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非本公司经授权的第三方工具/服务接入本软件和相关系统；</p>\n" +
    "        <p>通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>\n" +
    "        <p>通过非本公司开发、授权的第三方软件、插件、外挂、系统，登录或使用本软件及服务，或制作、发布、传播上述工具；</p>\n" +
    "        <p>自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；</p>\n" +
    "        <p>其他未经本公司明示授权的行为；</p>\n" +
    "        <p>其他违法法律法规、政策的行为。</p>\n" +
    "        <p>八、服务期限与终止</p>\n" +
    "        <p>发生下列情形，服务提前终止：</p>\n" +
    "        <p>双方协商一致提前终止的；</p>\n" +
    "        <p>用户购买的软件使用期届满且未及时续费的；</p>\n" +
    "        <p>用户严重违反本协议条款（包括但不限于a.用户未按照协议约定履行付款义务，b.用户严重违反法律规定等），本公司有权提前终止服务，并不退还用户已经支付的费用；</p>\n" +
    "        <p>用户理解并充分认可，虽然本公司已经建立（并将根据技术的发展不断完善）必要的技术措施来防御包括计算机病毒、网络入侵和攻击破坏（包括但不限于DDOS）等危害网络安全事项或行为（以下统称该等行为），但鉴于网络安全技术的局限性、相对性以及该等行为的不可预见性，如因此终止服务的，本公司将按照实际提供服务月份计算（不足一个月的按一个月计）服务费，将剩余款项（如有）返还；</p>\n" +
    "        <p>如本公司原因，造成用户连续72小时不能正常使用服务的，用户可以终止服务，本公司将按照实际提供服务月份计算（不足一个月的按一个月计）服务费，将剩余款项（如有）返还。</p>\n" +
    "        <p>九、争议解决</p>\n" +
    "        <p>本协议的订立、执行和解释及争议解决均应适用中华人民共和国法律。因双方就本协议的签订、履行或解释发生争议，双方应友好协商解决，协商不成的，任何一方均应向本公司住所地人民法院起诉。</p>\n" +
    "        <p>十、其他</p>\n" +
    "        <p>如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部分则仍具有法律效力。\n" +
    "            本协议标题仅为方便阅读所设，非对条款的定义、限制、解释或描述其范围或界限。</p>\n" +
    "        <p>杭州码耘网络技术有限公司</p>\n" +
    "        <p>版权所有 ? 2014-2020 杭州码耘网络技术有限公司 浙ICP备14029024号-1</p>\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "        <!-- <button type=\"button\" class=\"btn btn-primary\" ng-click=\"vm.save()\">同意</button>\n" +
    "        <button type=\"button\" class=\"btn btn-default\" ng-click=\"vm.disagree()\">不同意</button> -->\n" +
    "        <button type=\"button\" class=\"btn btn-default\" ng-click=\"vm.cancel()\">关闭</button>\n" +
    "    </div>\n" +
    "</mg-box>");
  $templateCache.put("tpl/register.htm",
    "<div class=\"row\">\n" +
    "    <div class=\"col-xs-6\">\n" +
    "        <div class=\"txt-erp\">\n" +
    "            芒果店长<br>\n" +
    "            让跨境生意更简单\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-6\">\n" +
    "        <div class=\"panel-login panel-login-right\">\n" +
    "            <div class=\"panel-heading1\"><strong>新用户注册</strong></div>\n" +
    "            <div class=\"panel-body1\">\n" +
    "                <form name=\"vm.signup\" ng-submit=\"vm.submitRegister()\" method=\"post\" novalidate autocomplete=\"off\">\n" +
    "                    <fieldset>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.loginName.$touched || vm.signup.$submitted) && vm.signup.loginName.$invalid}\">\n" +
    "                            <span class=\"block input-icon\">\n" +
    "                                <input type=\"text\" name=\"loginName\" ng-model=\"vm.data.name\" class=\"form-control\"\n" +
    "                                    placeholder=\"用户名\" required=\"required\" maxlength=\"32\" minlength=\"5\" mg-as-code\n" +
    "                                    mg-valid-uname />\n" +
    "                                <i class=\"ace-icon mg-icon mg-icon-yonghuming\"></i>\n" +
    "                            </span>\n" +
    "                            <div ng-messages=\"vm.signup.loginName.$error\"\n" +
    "                                ng-if=\"vm.signup.loginName.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入用户名</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"minlength\">用户名不能小于5位</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgAsCode\">用户名只能为字母、数字、下划线组成</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgValidUname\">用户名已存在</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.plainPassword.$touched || vm.signup.$submitted) && vm.signup.plainPassword.$invalid}\">\n" +
    "                            <span class=\"block input-icon\">\n" +
    "                                <input type=\"password\" name=\"plainPassword\" ng-model=\"vm.data.password\"\n" +
    "                                    class=\"form-control\" placeholder=\"密码\" required=\"required\" minlength=\"8\"\n" +
    "                                    maxlength=\"20\" mg-as-pwd tooltip-placement=\"right\" tooltip-trigger=\"focus\" tooltip=\"特殊符号为以下几种._~!@#$^&*\"/>\n" +
    "                                <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                            </span>\n" +
    "                            <div ng-messages=\"vm.signup.plainPassword.$error\"\n" +
    "                                ng-if=\"vm.signup.plainPassword.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入密码</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgAsPwd\">密码长度8-20，至少包含一个英文字母、一个数字和一个特殊字符</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"minlength\">密码长度不能小于8位</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.confirmPassword.$touched || vm.signup.$submitted) && vm.signup.confirmPassword.$invalid}\">\n" +
    "                            <span class=\"block input-icon\">\n" +
    "                                <input type=\"password\" name=\"confirmPassword\" ng-model=\"vm.data.repeatPassword\"\n" +
    "                                    class=\"form-control\" placeholder=\"确认密码\" required=\"required\" maxlength=\"20\"\n" +
    "                                    repeatpwd=\"{{vm.data.password}}\" />\n" +
    "                                <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                            </span>\n" +
    "                            <div ng-messages=\"vm.signup.confirmPassword.$error\"\n" +
    "                                ng-if=\"vm.signup.confirmPassword.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入确认密码</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"repeatpwd\">两次密码不一致</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.mobile.$touched || vm.signup.$submitted) && vm.signup.mobile.$invalid}\">\n" +
    "                            <span class=\"block input-icon\">\n" +
    "\n" +
    "                                <input type=\"text\" name=\"mobile\" ng-model=\"vm.data.mobile\" class=\"form-control\"\n" +
    "                                    placeholder=\"手机号\" maxlength=\"11\" required=\"required\"\n" +
    "                                    mg-valid-mobile style=\"padding-left: 60px;\"/>\n" +
    "                                <div class=\"flag-container\" dropdown>\n" +
    "                                    <div class=\"selected-flag dropdown-toggle\"  dropdown-toggle>\n" +
    "                                        <div class=\"iti-flag\" ng-class=\"vm.selcted.className\"></div>\n" +
    "                                        <div class=\"iti-arrow\"></div>\n" +
    "                                    </div>\n" +
    "                                    <ul class=\"dropdown-menu\" style=\"width: 350px;height: 236px;\">\n" +
    "                                        <input type=\"text\"  ng-model=\"vm.phoneKey\" class=\"search-input\" style=\"width: 350px; position: absolute; top: 0; left: -1px;z-index: 99999; box-shadow: 1px 1px 4px rgba(0, 0, 0, .2); border: 1px solid #ccc;\" ng-change=\"vm.phoneChange(vm.phoneKey)\" placeholder=\"快速搜索\">\n" +
    "                                        <ul class=\"country-list\">\n" +
    "                                            <li id=\"a\" ng-style=\"{'color':vm.selected.color}\" ng-click=\"vm.checkPhone(item)\" ng-repeat=\"item in vm.selectList\" class=\"country preferred active\" data-dial-code=\"86\" data-country-code=\"cn\">\n" +
    "                                                <div class=\"flag-box\">\n" +
    "                                                    <div class=\"iti-flag\" ng-class=\"item.className\"></div>\n" +
    "                                                </div>\n" +
    "                                                <span class=\"country-name\">{{item.english_name}}({{item.chinese_name}})</span>\n" +
    "                                                <span class=\"dial-code\">{{item.areacode}}</span>\n" +
    "                                            </li>\n" +
    "                                        </ul>\n" +
    "                                    </ul>\n" +
    "                                </div>\n" +
    "\n" +
    "                                <i class=\"ace-icon mg-icon mg-icon-shouji\"></i>\n" +
    "                            </span>\n" +
    "                            <div ng-messages=\"vm.signup.mobile.$error\"\n" +
    "                                ng-if=\"vm.signup.mobile.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请填写手机号</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"pattern\">手机格式不正确</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgValidMobile\">手机号已被使用</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.mobileCode.$touched || vm.signup.$submitted) && vm.signup.mobileCode.$invalid}\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <span class=\"col-xs-6\">\n" +
    "                                    <input type=\"text\" name=\"mobileCode\" ng-model=\"vm.data.mobileCode\"\n" +
    "                                        class=\"form-control imgcode\" placeholder=\"手机验证码\" required=\"required\"\n" +
    "                                        maxlength=\"6\" required=\"required\" mg-valid-mobile-code />\n" +
    "                                </span>\n" +
    "                                <span class=\"col-xs-6\">\n" +
    "                                    <button ng-show=\"vm.idle\" type=\"button\" class=\"btn btn-default btn-block\"\n" +
    "                                        ng-click=\"vm.sendSMS()\">发送验证码</button>\n" +
    "                                    <button ng-hide=\"vm.idle\" type=\"button\" class=\"btn btn-default btn-block\"\n" +
    "                                        disabled>重新发送\n" +
    "                                        {{vm.second}}秒</button>\n" +
    "                                </span>\n" +
    "                            </div>\n" +
    "                            <div ng-messages=\"vm.signup.mobileCode.$error\"\n" +
    "                                ng-if=\"vm.signup.mobileCode.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入手机验证码</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgValidMobileCode\">\n" +
    "                                    {{vm.signup.mobileCode._errorMsg}}\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"space-6\"></div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.imgCode.$touched || vm.signup.$submitted) && vm.signup.imgCode.$invalid}\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <span class=\"col-xs-6\">\n" +
    "                                    <input type=\"text\" name=\"imgCode\" ng-model=\"vm.data.code\"\n" +
    "                                        class=\"form-control imgcode\" placeholder=\"验证码\" required=\"required\" maxlength=\"4\"\n" +
    "                                        mg-valid-code />\n" +
    "                                </span>\n" +
    "                                <span class=\"col-xs-6\">\n" +
    "                                    <img class=\"imgcode\" ng-src=\"{{vm.data.img}}\" ng-click=\"vm.refresh()\"\n" +
    "                                        title=\"点击刷新验证码\" />\n" +
    "                                </span>\n" +
    "                            </div>\n" +
    "                            <div ng-messages=\"vm.signup.imgCode.$error\"\n" +
    "                                ng-if=\"vm.signup.imgCode.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入验证码</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgValidCode\">验证码错误</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"space-6\"></div>\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <button type=\"submit\" class=\"btn btn-sm btn-primary btn-block\">\n" +
    "                                <span class=\"bigger-110\">注册</span>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group no-margin\">\n" +
    "                            <label class=\"checkbox-inline no-margin\"><input ng-model=\"vm.agreementChecked\" type=\"checkbox\" ng-true-value=\"true\" ng-false-value=\"false\" />我已阅读并同意<a href=\"javascript:;\" ng-click=\"vm.openAgreement()\">《芒果店长ERP服务协议》</a></label>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\">\n" +
    "                            已拥有账号？\n" +
    "                            <a href=\"#/signin\" data-target=\"#login-box\">\n" +
    "                                马上登录\n" +
    "                            </a>\n" +
    "                            {{vm.phoneKey}}-phoneKey\n" +
    "                        </div>\n" +
    "                    </fieldset>\n" +
    "                </form>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"row img-box\">\n" +
    "    <div class=\"col-sm-3 hidden-xs\" ng-repeat=\"item in vm.adList\">\n" +
    "        <a href=\"{{item.url}}\" target=\"_blank\" ng-click=\"vm.closelogin(item.id)\"><img src=\"{{item.imgUrl}}\" alt=\"shopee\" /></a>\n" +
    "    </div>\n" +
    "    <!-- <div class=\"col-sm-3 hidden-xs\">\n" +
    "        <a href=\"https://shopee.cn/s/gECPiRq\" target=\"_blank\"><img src=\"//mangoerpbucket.oss-cn-hangzhou.aliyuncs.com/newsbanner/2021/20210817-shopee.png\" alt=\"shopee\" /></a>\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-3 hidden-xs\">\n" +
    "        <a href=\"/bridge/clientStatistics?code=AD0005\" target=\"_blank\"><img\n" +
    "            src=\"//sz2.mangoerp.com/ckeditor/2019-07-11/1562807442485.png\" alt=\"kilimall\" /></a>\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-3 hidden-xs\">\n" +
    "        <a href=\"/bridge/clientStatistics?code=AD0001\" target=\"_blank\"><img\n" +
    "                src=\"//sz2.mangoerp.com/ckeditor/2019-05-16/1558000248579.png\" alt=\"pingpong\" /></a>\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-3 hidden-xs\">\n" +
    "        <a href=\"https://sale.1688.com/sale/dacu/commonnew/1223nhjyjdfbyzhcJ5.html?__pageId__=231359&cms_id=231359&wh_pha=true&__existtitle__=1&enableWK=YES&tracelog=1223mango&fromkv=1223mango\" target=\"_blank\"><img\n" +
    "           src=\"http://img2.mangoerp.com/userbucket/adminadmin/2021-12-21/1640067274615.png\" alt=\"1688\" /></a>\n" +
    "    </div> -->\n" +
    "</div>\n" +
    "");
  $templateCache.put("tpl/scan.htm",
    "<div class=\"row\" >\n" +
    "  <div class=\"col-xs-6\">\n" +
    "      <div class=\"txt-erp\">\n" +
    "          芒果店长<br>\n" +
    "          让跨境生意更简单\n" +
    "      </div>\n" +
    "  </div>\n" +
    "    <!-- 扫码登录 -->\n" +
    "    <div class=\"col-xs-6 scan-code\" ng-if=\"vm.scanVisible\">\n" +
    "        <div class=\"panel-login panel-login-right\">\n" +
    "        <div class=\"scan-code__head\">\n" +
    "            <div class=\"scan-code__title\">扫码登录</div>\n" +
    "            <div class=\"scan-code__mask\" ng-if=\"vm.maskVisible\"></div>\n" +
    "            <div class=\"scan-code__mask--text\" ng-if=\"vm.maskTestVisible\" >\n" +
    "            <i class=\"ace-icon mg-icon mg-icon-shuaxin-o scan-code-icon\" style=\"font-size: 25px; cursor: pointer;\" ng-click=\"vm.scanRefresh()\"></i>\n" +
    "            <div>二维码已过期，请重新刷新</div>\n" +
    "            </div>\n" +
    "            <div class=\"container__changeCode\">\n" +
    "                <a href=\"/login#/signin\">\n" +
    "                    <img src=\"//img2.mangoerp.com/userbucket/adminadmin/2022-10-24/1666579225252.png\" alt=\"\">\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"panel-body1 scan-code__box\" ng-if=\"vm.scanCode\">\n" +
    "            <image class=\"scan-code__image\" src=\"{{vm.scanCode}}\" mode=\"aspectFit|aspectFill|widthFix\" lazy-load=\"false\" binderror=\"\" bindload=\"\" />\n" +
    "        </div>\n" +
    "        <div class=\"scan-code__foot\">\n" +
    "            <i class=\"ace-icon mg-icon mg-icon-weixin\" style=\"color: #30bf5f;\"></i>\n" +
    "            <span>微信扫码登录</span>\n" +
    "        </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <!-- 选择登陆账号 -->\n" +
    "    <div class=\"select-container\" ng-if=\"vm.userVisible\">\n" +
    "        <div class=\"select__user\">\n" +
    "          <div class=\"select__title\">请选择需要登陆的芒果账号</div>\n" +
    "        </div>\n" +
    "        <!-- 选择账号登陆 -->\n" +
    "        <div class=\"binging-tabs-box__boxOne--headTwo\" ng-if=\"vm.selectHeadTwo\">\n" +
    "          <i class=\"ace-icon mg-icon mg-icon-a-zuixin-jinchengzhuangtaishuomingtishi-gc boxOne-warn\"></i>\n" +
    "          <span class=\"boxOne-text\" style=\"font-size: 14px;\">{{vm.selectText || ''}}</span>\n" +
    "        </div>\n" +
    "        <div class=\"select__content\">\n" +
    "          <div class=\"select__card \" ng-repeat=\"item in vm.accountList track by $index\" ng-click=\"vm.selectUser($index)\" ng-class=\"{'card-active': $index === vm.selectIndex}\">\n" +
    "            <div class=\"select__card--left\" >\n" +
    "              <img src=\"{{item.headUrl}}\" alt=\"\" class=\"left-image\">\n" +
    "            </div>\n" +
    "            <div class=\"select__card--right\">\n" +
    "              <div class=\"right-user\">\n" +
    "                <span class=\"right-user__left\">用户名</span>\n" +
    "                <span class=\"right-user__right\">{{item.loginName}}</span>\n" +
    "              </div>\n" +
    "              <div class=\"right-phone\">\n" +
    "                <span class=\"right-phone__left\">手机</span>\n" +
    "                <span class=\"right-phone__right\">{{item.mobile}}</span>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <i class=\"ace-icon mg-icon mg-icon-fangxiangjiantou card-arrow\" ng-click=\"vm.jumpLogin(item, true)\" style=\"cursor: pointer;\"></i>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <!--  绑定其他账号 -->\n" +
    "        <div class=\"select__footer\">\n" +
    "          <div class=\"footer__head\" ng-click=\"vm.bingingOther()\">\n" +
    "            <span>绑定其他账号</span>\n" +
    "            <i class=\"ace-icon mg-icon mg-icon-fangxiangjiantou footer-arrow\"></i>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <!-- 用户登录绑定 -->\n" +
    "    <div class=\"binding-container\" ng-if=\"vm.userBinding\" style=\"height: {{vm.boxTwo ? '528px' : '386px'}}\">\n" +
    "        <ul class=\"binging-tabs\">\n" +
    "            <li ng-class=\"{'binging-active': vm.boxOne}\" ng-click=\"vm.alreadyOne('boxOne')\">已有芒果账号，请绑定</li>\n" +
    "            <li ng-click=\"vm.alreadyOne('boxTwo')\" ng-class=\"{'binging-active': vm.boxTwo}\">没有芒果账号，请完成注册</li>\n" +
    "        </ul>\n" +
    "        <!-- 对应内容 -->\n" +
    "        <div class=\"binging-boxs\">\n" +
    "            <!-- 已有芒果账号，请绑定 -->\n" +
    "            <div class=\"binging-tabs-box binging-tabs-box__boxOne\" ng-if=\"vm.boxOne\">\n" +
    "                <div class=\"binging__box\">\n" +
    "                    <!-- 绑定 -->\n" +
    "                    <div class=\"head-binding binging-tabs-box__boxOne--head\" ng-if=\"vm.boxOneError\">\n" +
    "                        <i class=\"fa fa-exclamation-circle boxOne-explain\"></i>\n" +
    "                        <span class=\"boxOne-text\">首次扫码登陆，请先绑定芒果账号，完成绑定后可以微信账号一键登陆哦</span>\n" +
    "                    </div>\n" +
    "                    <!-- 重复绑定 -->\n" +
    "                    <div class=\"head-rebinding binging-tabs-box__boxOne--headTwo\" ng-if=\"vm.boxTwoError\">\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-a-zuixin-jinchengzhuangtaishuomingtishi-gc boxOne-warn\"></i>\n" +
    "                        <span class=\"boxOne-text\">{{vm.boxTwoText}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            <div class=\"boxOne-container\">\n" +
    "                <form name=\"signin\" novalidate method=\"post\">\n" +
    "                <fieldset>\n" +
    "                    <div class=\"form-group\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input name=\"username\" id=\"username\" type=\"text\" ng-model=\"vm.userInfo.username\" class=\"form-control\" placeholder=\"用户名\" required=\"required\" />\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-yonghuming\"></i>\n" +
    "                    </span>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\">\n" +
    "                    <span class=\"block input-icon\">\n" +
    "                        <input name=\"password\" id=\"password\" type=\"password\" ng-model=\"vm.userInfo.password\" class=\"form-control\" placeholder=\"密码\" required=\"required\" />\n" +
    "                        <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                    </span>\n" +
    "                    <!-- <div>\n" +
    "                        <div class=\"help-block\" >请输入密码</div>\n" +
    "                    </div> -->\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\">\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\">\n" +
    "                    <button type=\"submit\" class=\"btn btn-sm btn-primary btn-block boxOne-container__btn\" ng-click=\"vm.userSubmit()\">\n" +
    "                        <span class=\"bigger-110\">登录</span>\n" +
    "                    </button>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\" style=\"display: flex; justify-content: space-between;\">\n" +
    "                        <span class=\"newUser\" ng-click=\"vm.alreadyOne('boxTwo')\"> 新用户注册 </span>\n" +
    "                        <a href=\"http://www.mangoerp.com/login#/forgot\" data-target=\"#forgot-box\" class=\"grey\"> 忘记密码 </a>\n" +
    "                    </div>\n" +
    "                </fieldset>\n" +
    "                </form>\n" +
    "            </div>\n" +
    "            </div>\n" +
    "            <!-- 没有芒果账号，请完成注册 -->\n" +
    "            <div class=\"binging-tabs-box binging-tabs-box__boxTwo\" ng-if=\"vm.boxTwo\">\n" +
    "              <form name=\"vm.signup\" ng-submit=\"vm.submitRegister()\" method=\"post\" novalidate autocomplete=\"off\">\n" +
    "                <fieldset>\n" +
    "                    <div class=\"form-group\"\n" +
    "                        ng-class=\"{'has-error':(vm.signup.loginName.$touched || vm.signup.$submitted) && vm.signup.loginName.$invalid}\">\n" +
    "                        <span class=\"block input-icon\">\n" +
    "                            <input type=\"text\" name=\"loginName\" ng-model=\"vm.data.name\" class=\"form-control\"\n" +
    "                                placeholder=\"用户名\" required=\"required\" maxlength=\"32\" minlength=\"5\" mg-as-code\n" +
    "                                mg-valid-uname />\n" +
    "                            <i class=\"ace-icon mg-icon mg-icon-yonghuming\"></i>\n" +
    "                        </span>\n" +
    "                        <div ng-messages=\"vm.signup.loginName.$error\"\n" +
    "                            ng-if=\"vm.signup.loginName.$touched || vm.signup.$submitted\">\n" +
    "                            <div class=\"help-block\" ng-message=\"required\">请输入用户名</div>\n" +
    "                            <div class=\"help-block\" ng-message=\"minlength\">用户名不能小于5位</div>\n" +
    "                            <div class=\"help-block\" ng-message=\"mgAsCode\">用户名只能为字母、数字、下划线组成</div>\n" +
    "                            <div class=\"help-block\" ng-message=\"mgValidUname\">用户名已存在</div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\"\n" +
    "                        ng-class=\"{'has-error':(vm.signup.plainPassword.$touched || vm.signup.$submitted) && vm.signup.plainPassword.$invalid}\">\n" +
    "                        <span class=\"block input-icon\">\n" +
    "                            <input type=\"password\" name=\"plainPassword\" ng-model=\"vm.data.password\"\n" +
    "                                class=\"form-control\" placeholder=\"密码\" required=\"required\" minlength=\"8\"\n" +
    "                                maxlength=\"20\" mg-as-pwd tooltip-placement=\"right\" tooltip-trigger=\"focus\" tooltip=\"特殊符号为以下几种._~!@#$^&*\"/>\n" +
    "                            <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                        </span>\n" +
    "                        <div ng-messages=\"vm.signup.plainPassword.$error\"\n" +
    "                            ng-if=\"vm.signup.plainPassword.$touched || vm.signup.$submitted\">\n" +
    "                            <div class=\"help-block\" ng-message=\"required\">请输入密码</div>\n" +
    "                            <div class=\"help-block\" ng-message=\"mgAsPwd\">密码长度8-20，至少包含一个英文字母、一个数字和一个特殊字符</div>\n" +
    "                            <div class=\"help-block\" ng-message=\"minlength\">密码长度不能小于8位</div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\"\n" +
    "                        ng-class=\"{'has-error':(vm.signup.confirmPassword.$touched || vm.signup.$submitted) && vm.signup.confirmPassword.$invalid}\">\n" +
    "                        <span class=\"block input-icon\">\n" +
    "                            <input type=\"password\" name=\"confirmPassword\" ng-model=\"vm.data.repeatPassword\"\n" +
    "                                class=\"form-control\" placeholder=\"确认密码\" required=\"required\" maxlength=\"20\"\n" +
    "                                repeatpwd=\"{{vm.data.password}}\" />\n" +
    "                            <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                        </span>\n" +
    "                        <div ng-messages=\"vm.signup.confirmPassword.$error\"\n" +
    "                            ng-if=\"vm.signup.confirmPassword.$touched || vm.signup.$submitted\">\n" +
    "                            <div class=\"help-block\" ng-message=\"required\">请输入确认密码</div>\n" +
    "                            <div class=\"help-block\" ng-message=\"repeatpwd\">两次密码不一致</div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\"\n" +
    "                        ng-class=\"{'has-error':(vm.signup.mobile.$touched || vm.signup.$submitted) && vm.signup.mobile.$invalid}\">\n" +
    "                        <span class=\"block input-icon\">\n" +
    "\n" +
    "                            <input type=\"text\" name=\"mobile\" ng-model=\"vm.data.mobile\" class=\"form-control\"\n" +
    "                                placeholder=\"手机号\" maxlength=\"11\" required=\"required\"\n" +
    "                                mg-valid-mobile style=\"padding-left: 60px;\"/>\n" +
    "\n" +
    "                            <div class=\"flag-container\">\n" +
    "                                <div class=\"selected-flag\" ng-click=\"vm.showPhone()\">\n" +
    "                                    <div class=\"iti-flag\" ng-class=\"vm.selcted.className\"></div>\n" +
    "                                    <div class=\"iti-arrow\"></div>\n" +
    "                                </div>\n" +
    "                                <ul class=\"country-list\" ng-show=\"vm.phoneflag\">\n" +
    "                                    <li id=\"a\" ng-style=\"{color:vm.selected.color}\" ng-click=\"vm.checkPhone(item)\" ng-repeat=\"item in vm.phoneList\" class=\"country preferred active\" data-dial-code=\"86\" data-country-code=\"cn\">\n" +
    "                                        <div class=\"flag-box\">\n" +
    "                                            <div class=\"iti-flag\" ng-class=\"item.className\"></div>\n" +
    "                                        </div>\n" +
    "                                        <span class=\"country-name\">{{item.english_name}}({{item.chinese_name}})</span>\n" +
    "                                        <span class=\"dial-code\">{{item.areacode}}</span>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <i class=\"ace-icon mg-icon mg-icon-shouji\"></i>\n" +
    "                        </span>\n" +
    "                        <div ng-messages=\"vm.signup.mobile.$error\"\n" +
    "                            ng-if=\"vm.signup.mobile.$touched || vm.signup.$submitted\">\n" +
    "                            <div class=\"help-block\" ng-message=\"required\">请填写手机号</div>\n" +
    "                            <div class=\"help-block\" ng-message=\"pattern\">手机格式不正确</div>\n" +
    "                            <div class=\"help-block\" ng-message=\"mgValidMobile\">手机号已被使用</div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\"\n" +
    "                        ng-class=\"{'has-error':(vm.signup.mobileCode.$touched || vm.signup.$submitted) && vm.signup.mobileCode.$invalid}\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <span class=\"col-xs-6\">\n" +
    "                                <input type=\"text\" name=\"mobileCode\" ng-model=\"vm.data.mobileCode\"\n" +
    "                                    class=\"form-control imgcode\" placeholder=\"手机验证码\" required=\"required\"\n" +
    "                                    maxlength=\"6\" required=\"required\" mg-valid-mobile-code />\n" +
    "                            </span>\n" +
    "                            <span class=\"col-xs-6\">\n" +
    "                                <button ng-show=\"vm.idle\" type=\"button\" class=\"btn btn-default btn-block\"\n" +
    "                                    ng-click=\"vm.sendSMS()\">发送验证码</button>\n" +
    "                                <button ng-hide=\"vm.idle\" type=\"button\" class=\"btn btn-default btn-block\"\n" +
    "                                    disabled>重新发送\n" +
    "                                    {{vm.second}}秒</button>\n" +
    "                            </span>\n" +
    "                        </div>\n" +
    "                        <div ng-messages=\"vm.signup.mobileCode.$error\"\n" +
    "                            ng-if=\"vm.signup.mobileCode.$touched || vm.signup.$submitted\">\n" +
    "                            <div class=\"help-block\" ng-message=\"required\">请输入手机验证码</div>\n" +
    "                            <div class=\"help-block\" ng-message=\"mgValidMobileCode\">\n" +
    "                                {{vm.signup.mobileCode._errorMsg}}\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"space-6\"></div>\n" +
    "                    <div class=\"form-group\"\n" +
    "                        ng-class=\"{'has-error':(vm.signup.imgCode.$touched || vm.signup.$submitted) && vm.signup.imgCode.$invalid}\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <span class=\"col-xs-6\">\n" +
    "                                <input type=\"text\" name=\"imgCode\" ng-model=\"vm.data.code\"\n" +
    "                                    class=\"form-control imgcode\" placeholder=\"验证码\" required=\"required\" maxlength=\"4\"\n" +
    "                                    mg-valid-code />\n" +
    "                            </span>\n" +
    "                            <span class=\"col-xs-6\">\n" +
    "                                <img class=\"imgcode\" ng-src=\"{{vm.data.img}}\" ng-click=\"vm.refresh()\"\n" +
    "                                    title=\"点击刷新验证码\" />\n" +
    "                            </span>\n" +
    "                        </div>\n" +
    "                        <div ng-messages=\"vm.signup.imgCode.$error\"\n" +
    "                            ng-if=\"vm.signup.imgCode.$touched || vm.signup.$submitted\">\n" +
    "                            <div class=\"help-block\" ng-message=\"required\">请输入验证码</div>\n" +
    "                            <div class=\"help-block\" ng-message=\"mgValidCode\">验证码错误</div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"space-6\"></div>\n" +
    "                    <div class=\"form-group\">\n" +
    "                        <button type=\"submit\" class=\"btn btn-sm btn-primary btn-block\">\n" +
    "                            <span class=\"bigger-110\">注册</span>\n" +
    "                        </button>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group no-margin\">\n" +
    "                        <label class=\"checkbox-inline no-margin\"><input ng-model=\"vm.agreementChecked\" type=\"checkbox\" ng-true-value=\"true\" ng-false-value=\"false\" />我已阅读并同意<a href=\"javascript:;\" ng-click=\"vm.openAgreement()\">《芒果店长ERP服务协议》</a></label>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\">\n" +
    "                        已拥有账号？\n" +
    "                        <a href=\"//www.mangoerp.com/erp#/erp/dashboard3\" data-target=\"#login-box\">\n" +
    "                            马上登录\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </fieldset>\n" +
    "            </form>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "<div class=\"row img-box\" ng-if=\"vm.adList.length\">\n" +
    "  <div class=\"col-sm-3 hidden-xs\" ng-repeat=\"item in vm.adList\">\n" +
    "      <a href=\"{{item.url}}\" target=\"_blank\" ng-click=\"vm.closelogin(item.id)\"><img src=\"{{item.imgUrl}}\" alt=\"shopee\" /></a>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
  $templateCache.put("tpl/shopee-register.htm",
    "<div class=\"row\">\n" +
    "    <div class=\"col-xs-6\">\n" +
    "        <div class=\"txt-erp\">\n" +
    "            芒果店长<br>\n" +
    "            让跨境生意更简单\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-6\">\n" +
    "        <div class=\"panel-login panel-login-right\">\n" +
    "            <div class=\"panel-heading1\"><strong>新用户注册{{vm.chenwei}}</strong></div>\n" +
    "            <div class=\"panel-body1\">\n" +
    "                <form name=\"vm.signup\" ng-submit=\"vm.submitRegister(true)\" method=\"post\" novalidate autocomplete=\"off\">\n" +
    "                    <fieldset>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.loginName.$touched || vm.signup.$submitted) && vm.signup.loginName.$invalid}\">\n" +
    "                            <span class=\"block input-icon\">\n" +
    "                                <input type=\"text\" name=\"loginName\" ng-model=\"vm.data.name\" class=\"form-control\"\n" +
    "                                    placeholder=\"用户名\" required=\"required\" maxlength=\"32\" minlength=\"5\" mg-as-code\n" +
    "                                    mg-valid-uname />\n" +
    "                                <i class=\"ace-icon mg-icon mg-icon-yonghuming\"></i>\n" +
    "                            </span>\n" +
    "                            <div ng-messages=\"vm.signup.loginName.$error\"\n" +
    "                                ng-if=\"vm.signup.loginName.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入用户名</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"minlength\">用户名不能小于5位</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgAsCode\">用户名只能为字母、数字、下划线组成</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgValidUname\">用户名已存在</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.plainPassword.$touched || vm.signup.$submitted) && vm.signup.plainPassword.$invalid}\">\n" +
    "                            <span class=\"block input-icon\">\n" +
    "                                <input type=\"password\" name=\"plainPassword\" ng-model=\"vm.data.password\"\n" +
    "                                    class=\"form-control\" placeholder=\"密码\" required=\"required\" minlength=\"8\"\n" +
    "                                    maxlength=\"20\" mg-as-pwd tooltip-placement=\"right\" tooltip-trigger=\"focus\" tooltip=\"特殊符号为以下几种._~!@#$^&*\" />\n" +
    "                                <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                            </span>\n" +
    "                            <div ng-messages=\"vm.signup.plainPassword.$error\"\n" +
    "                                ng-if=\"vm.signup.plainPassword.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入密码</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgAsPwd\">密码长度8-20，至少包含一个英文字母、一个数字和一个特殊字符</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"minlength\">密码长度不能小于8位</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.confirmPassword.$touched || vm.signup.$submitted) && vm.signup.confirmPassword.$invalid}\">\n" +
    "                            <span class=\"block input-icon\">\n" +
    "                                <input type=\"password\" name=\"confirmPassword\" ng-model=\"vm.data.repeatPassword\"\n" +
    "                                    class=\"form-control\" placeholder=\"确认密码\" required=\"required\" maxlength=\"20\"\n" +
    "                                    repeatpwd=\"{{vm.data.password}}\" />\n" +
    "                                <i class=\"ace-icon mg-icon mg-icon-mima\"></i>\n" +
    "                            </span>\n" +
    "                            <div ng-messages=\"vm.signup.confirmPassword.$error\"\n" +
    "                                ng-if=\"vm.signup.confirmPassword.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入确认密码</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"repeatpwd\">两次密码不一致</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.mobile.$touched || vm.signup.$submitted) && vm.signup.mobile.$invalid}\">\n" +
    "                            <span class=\"block input-icon\">\n" +
    "                                <input type=\"text\" name=\"mobile\" ng-model=\"vm.data.mobile\" class=\"form-control\"\n" +
    "                                    placeholder=\"手机号\" maxlength=\"11\" pattern=\"^1\\d{10}$\" required=\"required\"\n" +
    "                                    mg-valid-mobile />\n" +
    "                                <i class=\"ace-icon mg-icon mg-icon-shouji\"></i>\n" +
    "                            </span>\n" +
    "                            <div ng-messages=\"vm.signup.mobile.$error\"\n" +
    "                                ng-if=\"vm.signup.mobile.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请填写手机号</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"pattern\">手机格式不正确</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgValidMobile\">手机号已被使用</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.mobileCode.$touched || vm.signup.$submitted) && vm.signup.mobileCode.$invalid}\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <span class=\"col-xs-6\">\n" +
    "                                    <input type=\"text\" name=\"mobileCode\" ng-model=\"vm.data.mobileCode\"\n" +
    "                                        class=\"form-control imgcode\" placeholder=\"手机验证码\" required=\"required\"\n" +
    "                                        maxlength=\"6\" required=\"required\" mg-valid-mobile-code />\n" +
    "                                </span>\n" +
    "                                <span class=\"col-xs-6\">\n" +
    "                                    <button ng-show=\"vm.idle\" type=\"button\" class=\"btn btn-default btn-block\"\n" +
    "                                        ng-click=\"vm.sendSMS()\">发送验证码</button>\n" +
    "                                    <button ng-hide=\"vm.idle\" type=\"button\" class=\"btn btn-default btn-block\"\n" +
    "                                        disabled>重新发送\n" +
    "                                        {{vm.second}}秒</button>\n" +
    "                                </span>\n" +
    "                            </div>\n" +
    "                            <div ng-messages=\"vm.signup.mobileCode.$error\"\n" +
    "                                ng-if=\"vm.signup.mobileCode.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入手机验证码</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgValidMobileCode\">\n" +
    "                                    {{vm.signup.mobileCode._errorMsg}}\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"space-6\"></div>\n" +
    "                        <div class=\"form-group\"\n" +
    "                            ng-class=\"{'has-error':(vm.signup.imgCode.$touched || vm.signup.$submitted) && vm.signup.imgCode.$invalid}\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <span class=\"col-xs-6\">\n" +
    "                                    <input type=\"text\" name=\"imgCode\" ng-model=\"vm.data.code\"\n" +
    "                                        class=\"form-control imgcode\" placeholder=\"验证码\" required=\"required\" maxlength=\"4\"\n" +
    "                                        mg-valid-code />\n" +
    "                                </span>\n" +
    "                                <span class=\"col-xs-6\">\n" +
    "                                    <img class=\"imgcode\" ng-src=\"{{vm.data.img}}\" ng-click=\"vm.refresh()\"\n" +
    "                                        title=\"点击刷新验证码\" />\n" +
    "                                </span>\n" +
    "                            </div>\n" +
    "                            <div ng-messages=\"vm.signup.imgCode.$error\"\n" +
    "                                ng-if=\"vm.signup.imgCode.$touched || vm.signup.$submitted\">\n" +
    "                                <div class=\"help-block\" ng-message=\"required\">请输入验证码</div>\n" +
    "                                <div class=\"help-block\" ng-message=\"mgValidCode\">验证码错误</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"space-6\"></div>\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <button type=\"submit\" class=\"btn btn-sm btn-primary btn-block\">\n" +
    "                                <span class=\"bigger-110\">注册</span>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group no-margin\">\n" +
    "                            <label class=\"checkbox-inline no-margin\"><input ng-model=\"vm.agreementChecked\" type=\"checkbox\" ng-true-value=\"true\" ng-false-value=\"false\" />我已阅读并同意<a href=\"javascript:;\" ng-click=\"vm.openAgreement()\">《芒果店长ERP服务协议》</a></label>\n" +
    "                        </div>\n" +
    "                    </fieldset>\n" +
    "                </form>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
  $templateCache.put("tpl/weixin.htm",
    "<div id=\"login-box\" class=\"login-box visible widget-box no-border\">\n" +
    "    <div class=\"widget-body\">\n" +
    "        <div class=\"widget-main\">\n" +
    "            <div class=\"logintype logintype-wx\">\n" +
    "                <div class=\"poptip\">\n" +
    "                    <div class=\"poptip-arrow\">\n" +
    "                        <em></em>\n" +
    "                        <span></span>\n" +
    "                    </div>\n" +
    "                    <div class=\"poptip-content\">\n" +
    "                        <a href=\"#/signin\">密码登录</a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <a href=\"#/signin\"><img src=\"//asset.mangoerp.com/img/other/pc-login.png\" /></a>\n" +
    "            </div>\n" +
    "            <div id=\"wxlogin\">微信登录</div>\n" +
    "        </div><!-- /.widget-main -->\n" +
    "\n" +
    "        <div class=\"toolbar clearfix\">\n" +
    "            <div>\n" +
    "                <a href=\"#forgot\" data-target=\"#forgot-box\" class=\"forgot-password-link\">\n" +
    "                    <i class=\"ace-icon fa fa-arrow-left\"></i>\n" +
    "                    忘记密码\n" +
    "                </a>\n" +
    "            </div>\n" +
    "\n" +
    "            <div>\n" +
    "                <a href=\"#/signup\" data-target=\"#signup-box\" class=\"user-signup-link\">\n" +
    "                    注册\n" +
    "                    <i class=\"ace-icon fa fa-arrow-right\"></i>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div><!-- /.widget-body -->\n" +
    "</div><!-- /.login-box -->\n" +
    "");
}]);
